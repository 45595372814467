import {
  axiosGet,
  axiosPost,
  axiosPatch,
} from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const RETRIEVE_PTN = 'retrieving PTN data.';
const ASSIGN = 'assigning aiaGroup.';
const CLAIM = 'claiming aiaGroup.';
const UPDATE = 'updating aiaGroup.';
const REFRESH = 'refreshing aiaGroup';
const CREATE_COMMENT = 'creating aiaGroup comment.';
const RESOLVE = 'resolving aiaGroup.';
const ALERT = 'alerting aiaGroup.';

export default {
  async getNewestAiaGroups({ getters, commit }, payload) {
    return axiosGet(`${getters.aiaGroupApi()}&limitactive=49&limitinactive=1`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_PTN}`);
      console.log(`Retrieved ${res.data.length} newest aiaGroup incidents from backend`);
      commit('mergeSocObjectAiaGroups', res.data);
    }).catch((err) => console.log(err.message));
  },
  // Retrieve and merge a batch of 50 active and/or inactive AiaGroup objects, using the status,
  // score, and selected client as query parameters. Offset the query by the number of aiaGroups
  // in the store which match the query parameters we're sending.
  async getQueryFilteredAiaGroups({ getters, commit }, payload) {
    let uri = `${getters.aiaGroupApi()}&minscore=${getters.minSocObjectScore || 0}&maxscore=${getters.maxSocObjectScore || 100}`
      + `&offsetactive=${getters.socObjectsFilteredByQueryableParams('aiaGroup', true).length}`
      + `&offsetinactive=${getters.socObjectsFilteredByQueryableParams('aiaGroup', false).length}`;
    // Optionally, filter by clientId, date, and status.
    if (getters.selectedClientId) uri += `&clientid=${getters.selectedClientId}`;
    if (getters.socObjectStartDate) uri += `&start=${new Date(getters.socObjectStartDate).getTime()}`;
    if (getters.socObjectEndDate) uri += `&end=${new Date(getters.socObjectEndDate).getTime()}`;
    switch (getters.selectedStatusFilterId) {
      case 1: case 2: case 6: uri += '&limitactive=49&limitinactive=1'; break; // Active
      case 3: case 4: case 7: uri += '&limitactive=1&limitinactive=49'; break; // Terminal
      default: uri += '&limitactive=25&limitinactive=25'; break; // All statuses
    }
    return axiosGet(uri, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_PTN}`);
      console.log(`Retrieved ${res.data.length} query-filtered aiaGroup incidents from backend`);
      commit('mergeSocObjectAiaGroups', res.data);
    }).catch((err) => console.log(err.message));
  },
  async claimAiaGroup({ dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/aiagroups/claim`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CLAIM}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: postFields.aiaGroupUid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async checkAndClaimAiaGroup({ commit, dispatch, getters }, { vm, withEvents }) {
    const postFields = { aiaGroupUid: getters.selectedSocObject.uid, withEvents };
    return axiosGet(`${getters.apiPrefix}/aiagroups/${getters.selectedSocObject.uid}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CLAIM}`);
      return res.data.assigneeId === null || (res.data.assigneeId !== undefined
          && res.data.assigneeId === getters.loggedInUser('id'))
        ? dispatch('claimAiaGroup', { postFields, vm })
        : vm.$root.confirmSwal('Overwrite Assignment',
          'This aiaGroup was already claimed by another user',
          'Assign me anyway!',
          () => dispatch('claimAiaGroup', { postFields, vm }));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async updateAiaGroupDetails({ dispatch, getters }, { postFields, vm }) {
    return axiosPatch(`${getters.apiPrefix}/aiagroups/${getters.selectedSocObject.uid}`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: getters.selectedSocObject.uid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async refreshAiaGroup({ commit, getters }, { aiaGroupUid, vm }) {
    return aiaGroupUid && vm.$root.isValidUUID(aiaGroupUid)
    && axiosGet(`${getters.apiPrefix}/aiagroups/${aiaGroupUid}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${REFRESH}`);
      if (res.data.uid) commit('updateAiaGroupByUid', res.data);
    }).catch((error) => console.log(error.message));
  },
  async createNewAiaGroupComment({ commit, dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/aiagroupcomments`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_COMMENT}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedAiaGroupComment', { uid: getters.selectedSocObject.uid, type: 'regular' });
      return true;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async resolveAiaGroup({ commit, dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/aiagroups/resolve`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RESOLVE}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedAiaGroupComment', { uid: getters.selectedSocObject.uid, type: 'resolve' });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async alertAiaGroup({ dispatch, getters, commit }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/aiagroups/alert`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ALERT}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedAiaGroupComment', { uid: getters.selectedSocObject.uid, type: 'alert' });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async emailAlertAiaGroup({ dispatch, getters, commit }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/aiagroups/alert/email`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ALERT}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedAiaGroupComment', { uid: getters.selectedSocObject.uid, type: 'alert' });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async assignAiaGroup({ dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/aiagroups/assign`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ASSIGN}`);
      dispatch('refreshAiaGroup', { aiaGroupUid: postFields.aiaGroupUid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
};
