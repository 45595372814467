import { axiosGet } from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const RETRIEVE_ACTIONS = 'retrieving Respond/Email action descriptions.';
const RETRIEVE_BOXES = 'retrieving Boxes configured for Respond/Email polling.';
const RETRIEVE_TAGS = 'retrieving Respond/Email tag descriptions.';
const RETRIEVE_RESPOND_EMAILS = 'retrieving Respond/Email logs.';
const RETRIEVE_ACTION_SUMMARIES = 'retrieving Respond/Email action summaries.';
const RETRIEVE_DATA_LOSSES = 'retrieving Respond/Email data loss summaries.';
const RETRIEVE_USER_ANOMALIES = 'retrieving Respond/Email user anomaly summaries.';

export default {
  async getAllRespondEmailActions({ commit, getters }, payload) {
    return axiosGet(`${getters.respondEmailApi('actions')}&clientid=${payload.clientId || 0}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ACTIONS}`);
      commit('mergeRespondEmailObjects', { objects: res.data, type: 'actionDescriptions' });
    }).catch((err) => console.log(err.message));
  },
  async getAllRespondEmailBoxes({ commit, getters }, payload) {
    return axiosGet(`${getters.respondEmailApi('boxes')}&clientid=${payload.clientId || 0}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_BOXES}`);
      commit('mergeRespondEmailBoxes', { boxes: res.data });
    }).catch((err) => console.log(err.message));
  },
  async getAllRespondEmailTags({ commit, getters }, payload) {
    return axiosGet(`${getters.respondEmailApi('tags')}&clientid=${payload.clientId || 0}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_TAGS}`);
      commit('mergeRespondEmailObjects', { objects: res.data, type: 'tagDescriptions' });
    }).catch((err) => console.log(err.message));
  },
  async getAllRespondEmails({ commit, getters }, payload) {
    let url = `${getters.respondEmailApi()}&limit=${payload.limit || 50}`;
    if (getters.selectedClientId) url += `&clientid=${getters.selectedClientId}`;
    if (getters.selectedRespondEmailActionUid) url += `&actionuid=${getters.selectedRespondEmailActionUid}`;
    if (getters.selectedRespondEmailTagUid) url += `&taguid=${getters.selectedRespondEmailTagUid}`;
    if (getters.selectedRespondEmailRcptId) url += `&rcptstatus=${getters.selectedRespondEmailRcptStatus.name}`;
    return axiosGet(url, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_RESPOND_EMAILS}`);
      commit('mergeRespondEmailObjects', { objects: res.data, type: 'respondEmails' });
    }).catch((err) => console.log(err.message));
  },
  async getAllRespondEmailActionSummaries({ commit, getters }, payload) {
    return axiosGet(`${getters.respondEmailApi('actionsummaries')}&clientid=${payload.clientId || 0}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ACTION_SUMMARIES}`);
      commit('mergeRespondEmailObjects', { objects: res.data, type: 'actionSummaries' });
    }).catch((err) => console.log(err.message));
  },
  async getAllRespondEmailDataLosses({ commit, getters }, payload) {
    return axiosGet(`${getters.respondEmailApi('datalosses')}&clientid=${payload.clientId || 0}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_DATA_LOSSES}`);
      commit('mergeRespondEmailObjects', { objects: res.data, type: 'dataLosses' });
    }).catch((err) => console.log(err.message));
  },
  async getAllRespondEmailUserAnomalies({ commit, getters }, payload) {
    return axiosGet(`${getters.respondEmailApi('useranomalies')}&clientid=${payload.clientId || 0}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_USER_ANOMALIES}`);
      commit('mergeRespondEmailObjects', { objects: res.data, type: 'userAnomalies' });
    }).catch((err) => console.log(err.message));
  },
};
