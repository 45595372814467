/* eslint-disable security/detect-object-injection */
const sortSocObjects = (state) => {
  state.socObjects.sort((a, b) => { return a.creationTime < b.creationTime ? 1 : -1; });
};

export default {
  updateBreachCronIntervalId(state, { id }) {
    state.breachCronIntervalId = id;
  },
  clearSocObjects(state) {
    state.socObjects = [];
  },
  mergeSocObjectAiaGroups(state, aiaGroups) {
    aiaGroups.forEach((aiaGroup) => {
      aiaGroup.aiaGroupComments.sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
      });
      if (state.socObjects.findIndex((obj) => obj.socUid === `aiaGroup${aiaGroup.uid}`) === -1) {
        state.socObjects.push({
          ...aiaGroup,
          type: 'aiaGroup',
          socUid: `aiaGroup${aiaGroup.uid}`,
        });
      }
    });
    sortSocObjects(state);
  },
  mergeSocObjectAiaIncidents(state, aiaIncidents) {
    aiaIncidents.forEach((aiaIncident) => {
      aiaIncident.aiaIncidentComments.sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
      });
      if (state.socObjects.findIndex((obj) => obj.socUid === `aiaIncident${aiaIncident.uid}`) === -1) {
        state.socObjects.push({
          ...aiaIncident,
          type: 'aiaIncident',
          socUid: `aiaIncident${aiaIncident.uid}`,
        });
      }
    });
    sortSocObjects(state);
  },
  mergeSocObjectBreaches(state, breaches) {
    breaches.forEach((breach) => {
      breach.breachComments.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1; });
      if (!state.socObjects.find((obj) => obj.socUid === `breach${breach.uid}`)) {
        state.socObjects.push({
          ...breach,
          type: 'breach',
          socUid: `breach${breach.uid}`,
        });
      }
    });
    sortSocObjects(state);
  },
  setAsSelectedSocObject(state, { uid, type }) {
    state.selectedSocObjectUid = uid;
    state.selectedSocObjectType = type;
  },
  addProposedStatusField(state) {
    state.socObjects.forEach((object) => {
      if (object.proposedStatus === undefined) {
        object.proposedStatus = object.status;
      }
    });
  },
  resetProposedStatus(state, { uid, type }) {
    const index = state.socObjects.findIndex((obj) => obj.socUid === `${type}${uid}`);
    if (index !== -1) state.socObjects[index].proposedStatus = state.socObjects[index].status;
  },
  updateSocObjectStatusToProposed(state, { uid, type }) {
    const index = state.socObjects.findIndex((obj) => obj.socUid === `${type}${uid}`);
    if (index !== -1) state.socObjects[index].status = state.socObjects[index].proposedStatus;
  },
  updateAiaGroupByUid(state, aiaGroup) {
    aiaGroup.aiaGroupComments.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1; });
    const index = state.socObjects.findIndex((obj) => obj.socUid === `aiaGroup${aiaGroup.uid}`);
    if (index !== -1) {
      Object.keys(aiaGroup).forEach((key) => {
        state.socObjects[index][key] = aiaGroup[key];
      });
      state.socObjects[index].type = 'aiaGroup';
      state.socObjects[index].socUid = `aiaGroup${aiaGroup.uid}`;
    } else {
      state.socObjects.push({
        ...aiaGroup,
        type: 'aiaGroup',
        socUid: `aiaGroup${aiaGroup.uid}`,
      });
    }
  },
  updateAiaIncidentByUid(state, aiaIncident) {
    aiaIncident.aiaIncidentComments.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1; });
    const index = state.socObjects.findIndex((obj) => obj.socUid === `aiaIncident${aiaIncident.uid}`);
    if (index !== -1) {
      Object.keys(aiaIncident).forEach((key) => {
        state.socObjects[index][key] = aiaIncident[key];
      });
      state.socObjects[index].type = 'aiaIncident';
      state.socObjects[index].socUid = `aiaIncident${aiaIncident.uid}`;
    } else {
      state.socObjects.push({
        ...aiaIncident,
        type: 'aiaIncident',
        socUid: `aiaIncident${aiaIncident.uid}`,
      });
    }
  },
  updateBreachByUid(state, breach) {
    breach.breachComments.sort((a, b) => { return a.createdAt < b.createdAt ? 1 : -1; });
    const index = state.socObjects.findIndex((obj) => obj.socUid === `breach${breach.uid}`);
    if (index !== -1) {
      Object.keys(breach).forEach((key) => {
        state.socObjects[index][key] = breach[key];
      });
      state.socObjects[index].type = 'breach';
      state.socObjects[index].socUid = `breach${breach.uid}`;
    } else {
      state.socObjects.push({
        ...breach,
        type: 'breach',
        socUid: `breach${breach.uid}`,
      });
    }
  },
  saveAiaGroupComment(state, { uid, comment, type }) {
    const index = state.savedAiaGroupComments.findIndex((obj) => {
      return obj.uid === uid && obj.type === type;
    });
    if (index !== -1) state.savedAiaGroupComments[index].comment = comment;
    else state.savedAiaGroupComments.push({ uid, comment, type });
  },
  clearSavedAiaGroupComment(state, { uid, type }) {
    const index = state.savedAiaGroupComments.findIndex((obj) => {
      return obj.uid === uid && obj.type === type;
    });
    if (index !== -1) state.savedAiaGroupComments.splice(index, 1);
  },
  saveAiaComment(state, { uid, comment, type }) {
    const index = state.savedAiaComments.findIndex((obj) => {
      return obj.uid === uid && obj.type === type;
    });
    if (index !== -1) state.savedAiaComments[index].comment = comment;
    else state.savedAiaComments.push({ uid, comment, type });
  },
  clearSavedAiaIncidentComment(state, { uid, type }) {
    const index = state.savedAiaComments.findIndex((obj) => {
      return obj.uid === uid && obj.type === type;
    });
    if (index !== -1) state.savedAiaComments.splice(index, 1);
  },
  saveBreachComment(state, { uid, comment, type }) {
    const index = state.savedBreachComments.findIndex((obj) => {
      return obj.uid === uid && obj.type === type;
    });
    if (index !== -1) state.savedBreachComments[index].comment = comment;
    else state.savedBreachComments.push({ uid, comment, type });
  },
  clearSavedBreachComment(state, { uid, type }) {
    const index = state.savedBreachComments.findIndex((obj) => {
      return obj.uid === uid && obj.type === type;
    });
    if (index !== -1) state.savedBreachComments.splice(index, 1);
  },
};
