export default {
  // Auth modals
  totpSetup: { visible: false },
  totpInput: { visible: false },
  forgotPassword: { visible: false },
  // Admin modals (user-related)
  adminTotpSetup: { visible: false },
  adminTotpInput: { visible: false },
  createNewUser: { visible: false },
  createNewRole: { visible: false },
  updateRoleClients: { visible: false },
  updateUserRoles: { visible: false },
  // Admin modals (client-related)
  editAlertEmailBody: { visible: false },
  editAlertPdfPreamble: { visible: false },
  editCriticalBreachTags: { visible: false },
  editCriticalAiaCategories: { visible: false },
  editCriticalDeviceTags: { visible: false },
  editSocHours: { visible: false },
  updateBoxDetails: { visible: false },
  updateClientContactDetails: { visible: false },
  updateClientDetails: { visible: false },
  updateClientOutOfHoursAlerting: { visible: false },
  viewAndEditClientContacts: { visible: false },
  // User modals
  updateUserDetails: { visible: false },
  updateUserPassword: { visible: false },
  uploadAvatar: { visible: false },
  // SOC modals
  moreSocFilters: { visible: false },
  assignSocObject: { visible: false },
  alertAiaGroup: { visible: false },
  claimAiaGroup: { visible: false },
  alertAiaIncident: { visible: false },
  alertBreach: { visible: false },
  createNewAiaGroupComment: { visible: false },
  createNewAiaIncidentComment: { visible: false },
  createNewBreachComment: { visible: false },
  resolveAiaGroup: { visible: false },
  resolveAiaIncident: { visible: false },
  resolveBreach: { visible: false },
  showClientContacts: { visible: false },
};
