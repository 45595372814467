export default {
  addNewStatusFilter(state) {
    state.statusFilters[4].statuses.push('single');
    state.statusFilters.push({
      id: 8,
      name: 'Single View',
      statuses: ['single'],
      type: 'hybrid',
    });
  },
  setSearchedSocObjectUid(state, socObjectUid) {
    state.searchedSocObjectUid = socObjectUid;
  },
  setUrlSearchedSocObjectUid(state, urlSearchedSocObjectUid) {
    state.urlSearchedSocObjectUid = urlSearchedSocObjectUid;
  },
  setMinSocObjectScore(state, minScore) {
    state.minSocObjectScore = minScore;
  },
  setMaxSocObjectScore(state, maxScore) {
    state.maxSocObjectScore = maxScore;
  },
  updateSocObjectSearchString(state, { string }) {
    state.socObjectSearchString = string;
  },
  updateSelectedSocObjectTypeFilter(state, id) {
    state.selectedSocObjectTypeFilterId = id;
  },
  updateSocObjectUidSearchString(state, { string }) {
    state.searchedSocObjectUid = string;
  },
  updateSocObjectPBIDSearchString(state, { string }) {
    state.searchedPBID = string;
  },
  updateSelectedStatusFilter(state, { id }) {
    state.selectedStatusFilterId = id;
  },
  updateStartDateFilterOption(state, timestamp) {
    state.socObjectStartDate = timestamp;
  },
  updateEndDateFilterOption(state, timestamp) {
    state.socObjectEndDate = timestamp;
  },
};
