<template>
  <div id="analyst-root">
    <NavBar v-if="authenticated && $route.path !== '/login'" />
    <router-view />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
  components: {
    NavBar,
  },
  computed: {
    authenticated() {
      return this.$store.getters.loggedInWithValidToken();
    },
  },
};
</script>

<style>
#analyst-root {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  background-color: #111111;
}
.router-link-exact-active { color: #42b983; }
</style>
