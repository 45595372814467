/* eslint-disable security/detect-object-injection */
import Vue from 'vue';
import Vuex from 'vuex';
import modals from './modals';
import asmMutations from './mutations/asmMutations';
import clientAndBoxMutations from './mutations/clientAndBoxMutations';
import respondEmailMutations from './mutations/respondEmailMutations';
import searchAndFilterMutations from './mutations/searchAndFilterMutations';
import socObjectMutations from './mutations/socObjectMutations';
import userMutations from './mutations/userMutations';
import adminActions from './actions/adminActions';
import aiaGroupActions from './actions/aiaGroupActions';
import aiaIncidentActions from './actions/aiaIncidentActions';
import asmActions from './actions/asmActions';
import authActions from './actions/authActions';
import breachActions from './actions/breachActions';
import clientAndBoxActions from './actions/clientAndBoxActions';
import respondEmailActions from './actions/respondEmailActions';
import userActions from './actions/userActions';
import plugins from './plugins';

Vue.use(Vuex);

const trimmedUid = (uid) => { return uid ? uid.slice(0, 8) : ''; };

const socObjectPassesScoreFilter = (socObject, minScore, maxScore) => {
  let score = 0;
  switch (socObject.type) {
    case 'aiaGroup': score = socObject.groupScore; break;
    case 'aiaIncident': score = socObject.aiaScore; break;
    case 'breach': score = socObject.score * 100; break;
    default: return false;
  }
  return score >= minScore && score <= maxScore;
};

const socObjectPassesSearchStringFilter = (socObject, searchString) => {
  if (!searchString) return true;
  let searchFields = [
    socObject.uid,
    trimmedUid(socObject.uid),
    socObject.box.hostname,
    socObject.box.address,
    socObject.box.deployment.client.codename,
  ];
  if (socObject.analyst) {
    searchFields = searchFields.concat([socObject.analyst.email,
      socObject.analyst.firstName,
      socObject.analyst.lastName]);
  }
  switch (socObject.type) {
    case 'aiaGroup': searchFields = searchFields.concat([
      socObject.groupId,
      trimmedUid(socObject.groupId),
      socObject.category,
      socObject.previousIds,
      socObject.devices,
      socObject.initialDevices,
      socObject.mitreTactics,
      socObject.pinned ? ['pinned', 'pin'] : '',
      socObject.incidentEvents.map((event) => {
        return [event.uuid, trimmedUid(event.uuid), event.title, event.triggerDid];
      }).flat(1),
    ]).flat(2); break;
    case 'aiaIncident': searchFields = searchFields.concat([
      socObject.title,
      socObject.uuid,
      trimmedUid(socObject.uuid),
      socObject.category,
      socObject.currentGroup,
      trimmedUid(socObject.currentGroup),
      socObject.groupCategory,
      socObject.pinned ? ['pinned', 'pin'] : '',
      socObject.breachDevices.map((device) => {
        return [device.hostname || '', device.ip || '', device.credentials || ''];
      }).flat(2),
      socObject.relatedBreaches.map((breach) => [breach.modelName, breach.pbid]).flat(1),
    ]).flat(2); break;
    case 'breach': searchFields = searchFields.concat([
      socObject.name,
      socObject.pbid,
      socObject.uuid,
      trimmedUid(socObject.uuid),
      socObject.deviceDetails.hostname || '',
      socObject.deviceDetails.ip || '',
      socObject.deviceDetails.credentials || '',
      socObject.tags || '',
    ]).flat(2); break;
    default: return false;
  }
  return searchFields.filter((f) => f).map((f) => String(f)).find((field) => {
    return field && (field.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
      || searchString.toLowerCase().indexOf(field.toLowerCase()) !== -1);
  });
};

const socObjectPassesStatusFilter = (socObject, filterStatuses, urlSearchedSocObjectUid) => {
  return (filterStatuses.indexOf(socObject.status) !== -1
    || (filterStatuses.indexOf('single') !== -1 && (socObject.uid === urlSearchedSocObjectUid
      || trimmedUid(socObject.uid) === trimmedUid(urlSearchedSocObjectUid))));
};

const socObjectPassesDateFilter = (socObject, startDate, endDate) => {
  if (!startDate && !endDate) return true;
  const searchableDates = [socObject.updatedAt && new Date(socObject.updatedAt),
    socObject.creationTime && new Date(socObject.creationTime),
    socObject.time && new Date(socObject.time),
    socObject.end && new Date(parseInt(socObject.end, 10)),
    socObject.start && new Date(parseInt(socObject.start, 10))].filter((d) => d);
  if (!startDate) {
    return searchableDates.findIndex((d) => d <= endDate) !== -1;
  }
  if (!endDate) {
    return searchableDates.findIndex((d) => d >= startDate) !== -1;
  }
  return searchableDates.findIndex((d) => d >= startDate && d <= endDate) !== -1;
};

const socObjectPassesTypeFilter = (socObject, typeFilter) => {
  return typeFilter.id === 0 || socObject.type === typeFilter.name;
};

const socObjectPassesUidFilter = (socObject, searchUid) => {
  return !searchUid || [socObject.uid, trimmedUid(socObject.uid),
    socObject.uuid, trimmedUid(socObject.uuid)].indexOf(searchUid) !== -1;
};

const socObjectPassesPBIDFilter = (socObject, searchPbid) => {
  return !searchPbid || (socObject.type === 'aiaIncident'
    ? socObject.relatedBreaches.find((breach) => parseInt(breach.pbid, 10) === searchPbid)
    // Otherwise, should be type 'breach'.
    : parseInt(socObject.pbid, 10) === searchPbid);
};

const socObjectPassesClientIdFilter = (socObject, clientId) => {
  return !clientId || parseInt(socObject.box.deployment.clientId, 10) === clientId;
};

const sortByCreationTime = (socObjects) => {
  return socObjects.sort((a, b) => {
    return new Date(b.creationTime || parseInt(b.end, 10)).getTime()
      - new Date(a.creationTime || parseInt(a.end, 10)).getTime();
  });
};

const queryLogPassesSearchStringFilter = (queryLog, string) => {
  return queryLog.box && [queryLog.box.hostname, queryLog.box.deployment.client.codename]
    .find((field) => field && field.toLowerCase().indexOf(string.toLowerCase()) !== -1);
};

const respondEmailPassesTimeRangeFilter = (respondEmail, timeRange) => {
  const d = new Date();
  const m = d.getMonth();
  switch (timeRange) {
    case '1 Month': {
      d.setMonth(d.getMonth() - 1);
      // If still in same month, set date to last day of previous month
      if (d.getMonth() === m) d.setDate(0);
      break;
    }
    case '1 Week': {
      d.setDate(d.getDate() - 7);
      break;
    }
    case '24 Hours': {
      d.setDate(d.getDate() - 1);
      break;
    }
    default: return true;
  }
  d.setHours(0, 0, 0, 0);
  return (new Date(respondEmail.dtime)).getTime() >= d.getTime();
};

// A Vuex instance is created by combining state, getters, actions, mutations, and plugins.
const store = new Vuex.Store({
  state: {
    /** Constants */
    development: true,
    inputLength: {
      comment: 5000,
      information: 100,
    },
    modals: { ...modals },
    api: {
      prefix: (['development', 'test'].indexOf(process.env.NODE_ENV) !== -1) ? 'http://localhost:8001/api' : '/api',
      breachApi: '/breaches/soc?querystringenabled=1',
      aiaGroupApi: '/aiagroups/soc?querystringenabled=1',
      aiaIncidentApi: '/aiaincidents/soc?querystringenabled=1',
    },
    /** End Constants */

    /** Authentication state */
    accessTokenIntervalId: null,
    refreshFailCount: 0,
    user: {
      id: null,
      loggedIn: false,
      email: '',
      firstName: '',
      lastName: '',
      avatar: '',
      roles: [],
      permissions: {
        get: [],
        delete: [],
        post: [],
        patch: [],
      },
      clientIds: [],
    },
    qrData: '',
    xsrfToken: null,
    /** End Authentication state */

    /** User state */
    users: [],
    selectedUser: {
      id: null,
      email: '',
      firstName: '',
      lastName: '',
      avatar: '',
      roles: [],
      roleNames: [],
      clientIds: [],
    },
    roles: [],
    selectedRoleId: null,
    avatars: [],
    defaultAvatar: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    shiftData: {
      slot: null,
      handover: null,
      contract: { current: [], next: [] },
      trial: { current: [], next: [] },
      pov: { current: [], next: [] },
    },
    /** End User state */

    /** Client state */
    clients: [],
    selectedClientId: null,
    mockClientTags: [], // '❤️‍', '💰', '🛒', '🇺🇸', '🍴'
    clientTags: {
      isUsEyesOnly: '🇺🇸', bigDeal: '💰', noAte: '🍴', noPtn: '🆓',
    },
    selectedClientContact: {
      id: null,
      clientId: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      preferred_medium: '',
      socTeam: false,
    },
    /** End Client state */

    /** Box state */
    selectedBox: {
      id: null,
      deploymentId: null,
      hostname: '',
      address: '',
      shouldPollAia: false,
      shouldSyncAiaComments: false,
      shouldPollBreaches: false,
      shouldSyncBreachComments: false,
      verified: false,
      minPriority: '0',
      emOnly: false,
      pollingInterval: '1',
    },
    queryLogs: [],
    queryLogSearchString: '',
    queryLogStartDate: null,
    queryLogEndDate: null,
    /** End Box state */

    /** SOC Object state */
    socObjects: [],
    selectedSocObjectType: 'breach',
    selectedSocObjectUid: null,
    socObjectSearchString: '',
    searchedSocObjectUid: null,
    searchedPBID: null,
    urlSearchedSocObjectUid: null,
    minSocObjectScore: 0,
    maxSocObjectScore: 100,
    socObjectStartDate: null,
    socObjectEndDate: null,
    breachCronIntervalId: null,
    socObjectTypeFilters: [{ name: 'any', label: 'All SOC Object Types', id: 0 },
      { name: 'aiaGroup', label: 'AIA Incident Groups', id: 1 },
      { name: 'aiaIncident', label: 'AIA Incident Events', id: 2 },
      { name: 'breach', label: 'Model Breach', id: 3 }],
    selectedSocObjectTypeFilterId: 0,
    statusFilters: [{
      id: 1, name: 'New', statuses: ['new'], type: 'regular',
    }, {
      id: 2, name: 'In Progress', statuses: ['inprogress'], type: 'regular',
    }, {
      id: 3, name: 'Resolved', statuses: ['resolved'], type: 'regular',
    }, {
      id: 4, name: 'Alert Raised', statuses: ['alertraised'], type: 'regular',
    }, {
      id: 5, name: 'All', statuses: ['new', 'inprogress', 'resolved', 'alertraised'], type: 'hybrid',
    }, {
      id: 6, name: 'Active', statuses: ['new', 'inprogress'], type: 'hybrid',
    }, {
      id: 7, name: 'Terminal', statuses: ['resolved', 'alertraised'], type: 'hybrid',
    }],
    selectedStatusFilterId: 6, // default to 'active' state
    savedAiaGroupComments: [],
    savedAiaComments: [],
    savedBreachComments: [],
    availableSocTags: [],
    mockBreachTags: [
      // { text: '#ransomware' },
      // { text: '#eternalblue' },
      // { text: '#common device' },
      // { text: '#pen test active' },
      // { text: 'high priority' },
    ],
    /** End SOC Object state */

    /** ASM state */
    allAsmObjects: [],
    asmObjectStateFilters: [
      { id: 1, name: 'All' },
      { id: 2, name: 'New' },
      { id: 3, name: 'Unconfirmed' },
      { id: 4, name: 'Confirmed' },
      { id: 5, name: 'Archived' },
    ],
    selectedAsmObjectStateId: 1, // default to 'all'
    allAsmRisks: [],
    asmRiskTypeFilters: [
      { id: 0, name: 'All' },
      { id: 1, name: 'SSL' },
      { id: 2, name: 'Informational' },
      { id: 3, name: 'Vulnerable software' },
      // { id: 4, name: 'Content change' },
      // { id: 5, name: 'New file' },
      // { id: 6, name: 'Information leak' },
      // { id: 7, name: 'Outdated software' },
      { id: 8, name: 'Misconfiguration' },
      { id: 9, name: 'GDPR' },
      // { id: 10, name: 'Branding compliance' },
      // { id: 11, name: 'Dark web' },
      { id: 12, name: 'Reported' },
      // { id: 13, name: 'Social Media Threat' },
      // { id: 14, name: 'BGP Hijack' },
    ],
    selectedAsmRiskTypeId: 0,
    minAsmRiskScore: 'U',
    maxAsmRiskScore: 'F',
    /** End ASM state */

    /** RespondEmail state */
    // Constants
    respondEmailTimeRanges: [{ id: 0, name: '1 Month' }, { id: 1, name: '1 Week' }, { id: 2, name: '24 Hours' }],
    respondEmailRcptStatuses: [{ id: 1, name: 'delivered' }, { id: 2, name: 'junk' }],
    respondEmailDirections: ['Any Direction', 'inbound', 'outbound'],
    // Resources
    respondEmailActionDescriptions: [],
    respondEmailTagDescriptions: [],
    // Data Arrays
    respondEmailBoxes: [],
    respondEmails: [],
    respondEmailActionSummaries: [],
    respondEmailDataLosses: [],
    respondEmailUserAnomalies: [],
    // Mutables
    respondEmailSearchString: '',
    selectedRespondEmailDirection: 'Any Direction',
    selectedRespondEmailBoxId: 0,
    selectedRespondEmailTimeRangeId: 0,
    selectedRespondEmailRcptStatusId: 0,
    selectedRespondEmailActionUid: null,
    selectedRespondEmailTagUid: null,
    selectedRespondEmailUid: null,
    minRespondEmailScore: 0,
    maxRespondEmailScore: 100,
    /** End RespondEmail state */
  },
  getters: {
    /** Constant Getters */
    socObjectTypeFilters: (state) => state.socObjectTypeFilters,
    apiPrefix: (state) => `${state.api.prefix}`,
    breachApi: (state) => () => `${state.api.prefix}${state.api.breachApi}`,
    aiaGroupApi: (state) => () => `${state.api.prefix}${state.api.aiaGroupApi}`,
    aiaIncidentApi: (state) => () => `${state.api.prefix}${state.api.aiaIncidentApi}`,
    respondEmailApi: (state) => (type) => `${state.api.prefix}/respondemails${type ? `/${type}` : ''}?querystringenabled=1`,
    inputLength: (state) => state.inputLength,
    modals: (state) => state.modals,
    /** End Constant Getters */

    /** Authentication Getters */
    accessTokenIntervalId: (state) => state.accessTokenIntervalId,
    loggedInWithValidToken: (state) => () => {
      const expiresAt = localStorage.getItem('tokenExpiresAt');
      return (state.user.loggedIn
        && state.user.email.length
        && state.user.id
        && expiresAt
        && Date.now() < parseInt(expiresAt, 10));
    },
    loggedInUser: (state) => (key) => {
      return key === 'userName' ? (state.user.email || '<NO NAME>') : state.user[key];
    },
    qrData: (state) => state.qrData,
    checkPermission: (state) => ({ method, resource }) => {
      return state.user.permissions[method].indexOf(resource) !== -1;
    },
    xsrfToken: (state) => state.xsrfToken,
    /** End Authentication Getters */

    /** Users Getters */
    allUsers: (state) => state.users,
    selectedUser: (state) => state.selectedUser,
    user: (state) => state.user,
    userAvatarIndex: (state) => (userId) => state.avatars
      .findIndex((avatarObject) => userId === avatarObject.userId),
    avatar: (state, getters) => (userId) => {
      const index = getters.userAvatarIndex(userId);
      return index !== -1 ? state.avatars[index].img : state.defaultAvatar;
    },
    defaultAvatar: (state) => state.defaultAvatar,
    roles: (state) => state.roles,
    selectedRole: (state) => {
      return state.selectedRoleId
        && state.roles.find((object) => object.id === state.selectedRoleId);
    },
    /** End Users Getters */

    /** Clients and Boxes Getters */
    allClients: (state) => state.clients,
    selectedClientId: (state) => state.selectedClientId,
    selectedClient: (state) => {
      return state.selectedClientId
        && state.clients.find((object) => object.id === state.selectedClientId);
    },
    selectedClientContact: (state) => state.selectedClientContact,
    selectedBox: (state) => state.selectedBox,
    queryLogSearchString: (state) => state.queryLogSearchString,
    queryLogStartDate: (state) => state.queryLogStartDate,
    queryLogEndDate: (state) => state.queryLogEndDate,
    filteredQueryLogs: (state) => state.queryLogs
      .filter((queryLog) => queryLogPassesSearchStringFilter(queryLog, state.queryLogSearchString))
      .filter((queryLog) => socObjectPassesDateFilter(queryLog,
        state.queryLogStartDate, state.queryLogEndDate)),
    /** End Clients and Boxes Getters */

    /** SOC Getters */
    breachCronIntervalId: (state) => state.breachCronIntervalId,
    socObjects: (state) => state.socObjects,
    socObjectSearchString: (state) => state.socObjectSearchString,
    searchedSocObjectUid: (state) => state.searchedSocObjectUid,
    searchedPBID: (state) => state.searchedPBID,
    socObjectStartDate: (state) => state.socObjectStartDate,
    socObjectEndDate: (state) => state.socObjectEndDate,
    minSocObjectScore: (state) => state.minSocObjectScore,
    maxSocObjectScore: (state) => state.maxSocObjectScore,
    socObjectsFilteredByClientIdDateAndScore: (state, getters) => {
      return getters.socObjects.filter((socObject) => {
        return socObject
          && socObjectPassesClientIdFilter(socObject, parseInt(state.selectedClientId, 10))
          && socObjectPassesDateFilter(socObject,
            getters.socObjectStartDate, getters.socObjectEndDate)
          && socObjectPassesScoreFilter(socObject,
            getters.minSocObjectScore, getters.maxSocObjectScore);
      });
    },
    socObjectsFilteredByQueryableParams: (state, getters) => (type, active) => {
      return getters.socObjectsFilteredByClientIdDateAndScore.filter((socObject) => {
        return socObject.type === type && (active ? ['new', 'inprogress']
          : ['resolved', 'alertraised']).indexOf(socObject.status) !== -1;
      });
    },
    socObjectsFilteredBySelectedType: (state, getters) => {
      return getters.socObjects.filter((socObject) => {
        return socObjectPassesTypeFilter(socObject, getters.selectedSocObjectTypeFilter);
      });
    },
    filteredAndOrderedSocObjects: (state, getters) => {
      const socObjects = getters.socObjectsFilteredByClientIdDateAndScore.filter((socObject) => {
        return socObjectPassesTypeFilter(socObject, getters.selectedSocObjectTypeFilter)
          && socObjectPassesSearchStringFilter(socObject, state.socObjectSearchString)
          && socObjectPassesStatusFilter(socObject, getters.selectedStatusFilter.statuses,
            state.urlSearchedSocObjectUid)
          && socObjectPassesUidFilter(socObject, state.searchedSocObjectUid)
          && socObjectPassesPBIDFilter(socObject, parseInt(getters.searchedPBID, 10));
      });
      return sortByCreationTime(socObjects.filter((socObject) => {
        // Active socObjects
        return ['new', 'inprogress'].indexOf(socObject.status) !== -1;
      })).concat(sortByCreationTime(socObjects.filter((socObject) => {
        // Terminal socObjects
        return ['resolved', 'alertraised'].indexOf(socObject.status) !== -1;
      })));
    },
    filteredSocObjectStatus: (state) => (status) => state.statusFilters
      .filter((statusObject) => statusObject.type === status),
    selectedStatusFilterId: (state) => state.selectedStatusFilterId,
    selectedSocObjectTypeFilterId: (state) => state.selectedSocObjectTypeFilterId,
    selectedSocObjectTypeFilter: (state) => {
      return state.socObjectTypeFilters.find((o) => o.id === state.selectedSocObjectTypeFilterId);
    },
    selectedSocObjectType: (state) => state.selectedSocObjectType,
    selectedSocObject: (state) => {
      return state.selectedSocObjectUid && state.selectedSocObjectType && state.socObjects
        .find((obj) => obj.socUid === `${state.selectedSocObjectType}${state.selectedSocObjectUid}`);
    },
    selectedStatusFilter: (state) => {
      return state.statusFilters.find((fObj) => fObj.id === state.selectedStatusFilterId);
    },
    getSavedAiaGroupComment: (state) => ({ uid, type }) => {
      const index = state.savedAiaGroupComments
        .findIndex((obj) => obj.uid === uid && obj.type === type);
      return index !== -1
        ? state.savedAiaGroupComments[index].comment
        : '';
    },
    getSavedAiaComment: (state) => ({ uid, type }) => {
      const index = state.savedAiaComments
        .findIndex((obj) => obj.uid === uid && obj.type === type);
      return index !== -1
        ? state.savedAiaComments[index].comment
        : '';
    },
    getSavedBreachComment: (state) => ({ uid, type }) => {
      const index = state.savedBreachComments
        .findIndex((obj) => obj.uid === uid && obj.type === type);
      return (index !== -1)
        ? state.savedBreachComments[index].comment
        : '';
    },
    /** End SOC Getters */

    /** ASM Getters */
    asmObjectStateFilters: (state) => state.asmObjectStateFilters,
    selectedAsmObjectStateId: (state) => state.selectedAsmObjectStateId,
    selectedAsmObjectState: (state) => state.asmObjectStateFilters
      .find((obj) => obj.id === state.selectedAsmObjectStateId),
    allAsmObjectsForSelectedClient: (state) => {
      return state.allAsmObjects.filter((asmObject) => {
        return !state.selectedClientId
          || parseInt(asmObject.clientId, 10) === state.selectedClientId;
      });
    },
    filteredAndOrderedAsmObjects: (state, getters) => {
      // Filter by client and state ('All', 'New', 'Confirmed', 'Unconfirmed', 'Archived'),
      // then sort by securityrating, using updated_at to break ties.
      return getters.allAsmObjectsForSelectedClient.filter((asmObject) => {
        return getters.selectedAsmObjectState.name === 'All'
          || asmObject.state === getters.selectedAsmObjectState.name;
      }).sort((a, b) => {
        return (['f', 'e', 'd', 'c', 'b', 'a', 'u'].indexOf(a.securityrating)
              - ['f', 'e', 'd', 'c', 'b', 'a', 'u'].indexOf(b.securityrating))
              || b.updated_at - a.updated_at;
      });
    },
    asmRiskTypeFilters: (state) => state.asmRiskTypeFilters,
    selectedAsmRiskTypeId: (state) => state.selectedAsmRiskTypeId,
    allAsmRisks: (state) => state.allAsmRisks,
    allAsmRisksForSelectedClient: (state, getters) => {
      return getters.allAsmRisks.filter((asmRisk) => {
        return !state.selectedClientId
          || parseInt(asmRisk.clientId, 10) === parseInt(state.selectedClientId, 10);
      });
    },
    filteredAndOrderedAsmRisks: (state, getters) => {
      // Filter by client, score, and type, then sort by score, using started_at to break ties.
      return getters.allAsmRisksForSelectedClient.filter((asmRisk) => {
        return (state.minAsmRiskScore === 'U' || asmRisk.letterScore >= state.minAsmRiskScore)
            && (asmRisk.letterScore === 'U' || asmRisk.letterScore <= state.maxAsmRiskScore)
            && (!state.selectedAsmRiskTypeId
              || parseInt(asmRisk.type, 10) === state.selectedAsmRiskTypeId);
      }).sort((a, b) => {
        return b.score - a.score || (a.started_at > b.started_at ? -1 : 1);
      });
    },
    /** End ASM Getters */

    /** RespondEmail Getters */
    // Constants
    respondEmailRcptStatuses: (state) => state.respondEmailRcptStatuses,
    respondEmailTimeRanges: (state) => state.respondEmailTimeRanges,
    respondEmailDirections: (state) => state.respondEmailDirections,
    // Resources
    respondEmailActionDescriptions: (state) => state.respondEmailActionDescriptions,
    respondEmailTagDescriptions: (state) => state.respondEmailTagDescriptions,
    // Data Arrays
    allRespondEmails: (state) => state.respondEmails,
    allRespondEmailBoxes: (state) => state.respondEmailBoxes,
    allRespondEmailActionSummaries: (state) => state.respondEmailActionSummaries,
    allRespondEmailDataLosses: (state) => state.respondEmailDataLosses,
    allRespondEmailUserAnomalies: (state) => state.respondEmailUserAnomalies,
    // Search String
    respondEmailSearchString: (state) => state.respondEmailSearchString,
    // Selected Objects
    selectedRespondEmailUid: (state) => state.selectedRespondEmailUid,
    selectedRespondEmail: (state, getters) => getters.allRespondEmails
      .find((respondEmail) => respondEmail.uid === state.selectedRespondEmailUid),
    selectedRespondEmailDirection: (state) => state.selectedRespondEmailDirection,
    selectedRespondEmailBoxId: (state) => state.selectedRespondEmailBoxId,
    selectedRespondEmailBox: (state) => state.respondEmailBoxes
      .find((box) => box.id === state.selectedRespondEmailBoxId),
    selectedRespondEmailTimeRangeId: (state) => state.selectedRespondEmailTimeRangeId,
    selectedRespondEmailTimeRange: (state) => {
      return state.respondEmailTimeRanges[parseInt(state.selectedRespondEmailTimeRangeId, 10)];
    },
    selectedRespondEmailActionUid: (state) => state.selectedRespondEmailActionUid,
    selectedRespondEmailAction: (state) => state.respondEmailActionDescriptions
      .find((action) => action.uid === state.selectedRespondEmailActionUid),
    selectedRespondEmailTagUid: (state) => state.selectedRespondEmailTagUid,
    selectedRespondEmailTag: (state) => state.respondEmailTagDescriptions
      .find((tag) => tag.uid === state.selectedRespondEmailTagUid),
    selectedRespondEmailRcptStatusId: (state) => state.selectedRespondEmailRcptStatusId,
    selectedRespondEmailRcptStatus: (state) => state.respondEmailRcptStatuses
      .find((status) => status.id === parseInt(state.selectedRespondEmailRcptStatusId, 10)),
    // Filtered and Sorted arrays
    filteredRespondEmailActionDescriptions: (state) => {
      return state.selectedClientId
        ? state.respondEmailActionDescriptions.filter((obj) => {
          return parseInt(obj.box.deployment.clientId, 10) === parseInt(state.selectedClientId, 10);
        }) : state.respondEmailActionDescriptions;
    },
    filteredRespondEmailTagDescriptions: (state) => {
      return state.selectedClientId
        ? state.respondEmailTagDescriptions.filter((obj) => {
          return parseInt(obj.box.deployment.clientId, 10) === parseInt(state.selectedClientId, 10);
        }) : state.respondEmailTagDescriptions;
    },
    filteredAndSortedRespondEmailBoxes: (state, getters) => {
      let returnArray = getters.allRespondEmailBoxes;
      if (state.selectedClientId) {
        returnArray = returnArray.filter((box) => parseInt(state.selectedClientId, 10)
          === parseInt(box.deployment.clientId, 10));
      }
      if (state.respondEmailSearchString !== '') {
        returnArray = returnArray.filter((box) => [box.hostname || '', box.address || '',
          box.deployment.client.name || ''].find((field) => field.toLowerCase()
          .indexOf(state.respondEmailSearchString.toLowerCase()) !== -1));
      }
      return returnArray.sort((a, b) => (parseInt(a.deployment.clientId, 10)
        - parseInt(b.deployment.clientId, 10))
          || parseInt(a.id, 10) - parseInt(b.id, 10));
    },
    // Filter by score, search string, action, rcpt.status, tag, direction,
    // client, and time range, then sort by boxId and dtime.
    filteredAndSortedRespondEmails: (state, getters) => {
      return state.respondEmails.filter((email) => {
        return parseInt(email.model_score, 10) >= state.minRespondEmailScore
          && parseInt(email.model_score, 10) <= state.maxRespondEmailScore
          && (!state.respondEmailSearchString || [trimmedUid(email.uid),
            email.header_from,
            email.header_from_email,
            email.header_from_personal,
            email.header_subject,
            trimmedUid(email.uuid),
            email.box.hostname,
            email.box.address,
            email.box.deployment.client.codename]
            .concat(email.rcpts.map((rcpt) => rcpt.rcpt_to))
            .find((field) => field.toLowerCase()
              .indexOf(state.respondEmailSearchString.toLowerCase()) !== -1))
          && (!state.selectedRespondEmailActionUid || email.rcpts.map((rcpt) => rcpt.actions_taken)
            .flat(1).find((action) => action.uid === state.selectedRespondEmailActionUid))
          && (!state.selectedRespondEmailRcptStatusId || email.rcpts.find((rcpt) => rcpt.rcpt_status
            === getters.selectedRespondEmailRcptStatus.name))
          && (!state.selectedRespondEmailTagUid || email.rcpts.map((rcpt) => rcpt.tags)
            .flat(1).find((tag) => tag.uid === state.selectedRespondEmailTagUid))
          && ['Any Direction', email.direction].indexOf(state.selectedRespondEmailDirection) !== -1
          && (!state.selectedClientId
            || parseInt(email.box.deployment.clientId, 10) === parseInt(state.selectedClientId, 10))
          && respondEmailPassesTimeRangeFilter(email, getters.selectedRespondEmailTimeRange.name);
      }).sort((a, b) => Date.parse(b.dtime) - Date.parse((a.dtime)));
    },
    /** End RespondEmail Getters */
  },
  actions: {
    ...adminActions,
    ...aiaGroupActions,
    ...aiaIncidentActions,
    ...asmActions,
    ...authActions,
    ...breachActions,
    ...clientAndBoxActions,
    ...respondEmailActions,
    ...userActions,
  },
  mutations: {
    changeModalVisibility(state, { modal, visible }) {
      state.modals[modal].visible = visible;
    },
    ...asmMutations,
    ...clientAndBoxMutations,
    ...searchAndFilterMutations,
    ...respondEmailMutations,
    ...socObjectMutations,
    ...userMutations,
  },
  plugins,
});

export default store;
