/* eslint-disable security/detect-object-injection */
const mergeRespondEmailObject = (state, object, type) => {
  let stateKey;
  switch (type) {
    case 'respondEmails': { stateKey = 'respondEmails'; break; }
    case 'actionDescriptions':
    case 'tagDescriptions':
    case 'actionSummaries':
    case 'dataLosses':
    case 'userAnomalies': { stateKey = `respondEmail${type.charAt(0).toUpperCase()}${type.slice(1)}`; break; }
    default: return;
  }
  const index = state[stateKey].findIndex((obj) => obj.uid === object.uid);
  if (index === -1) {
    state[stateKey].push(object);
  } else {
    state[stateKey][index] = object;
  }
};

export default {
  clearRespondEmails(state) {
    state.respondEmails = [];
  },
  mergeRespondEmailBoxes(state, { boxes }) {
    boxes.forEach((box) => {
      const index = state.respondEmailBoxes.findIndex((obj) => obj.id === box.id);
      if (index === -1) {
        state.respondEmailBoxes.push(box);
      } else {
        state.respondEmailBoxes[index] = box;
      }
    });
  },
  mergeRespondEmailObjects(state, { objects, type }) {
    objects.forEach((object) => mergeRespondEmailObject(state, object, type));
  },
  updateRespondEmailSearchString(state, string) {
    state.respondEmailSearchString = string;
  },
  updateSelectedRespondEmail(state, uid) {
    state.selectedRespondEmailUid = uid;
  },
  updateSelectedRespondEmailBox(state, id) {
    state.selectedRespondEmailBoxId = id;
  },
  updateSelectedRespondEmailTimeRange(state, id) {
    state.selectedRespondEmailTimeRangeId = id;
  },
  updateSelectedRespondEmailDirection(state, direction) {
    state.selectedRespondEmailDirection = direction;
  },
  updateSelectedRespondEmailAction(state, uid) {
    state.selectedRespondEmailActionUid = uid;
  },
  updateSelectedRespondEmailTag(state, uid) {
    state.selectedRespondEmailTagUid = uid;
  },
  updateSelectedRespondEmailRcptStatus(state, statusId) {
    state.selectedRespondEmailRcptStatusId = statusId;
  },
  setMinRespondEmailScore(state, score) {
    state.minRespondEmailScore = score;
  },
  setMaxRespondEmailScore(state, score) {
    state.maxRespondEmailScore = score;
  },
};
