export default {
  updateAccessTokenIntervalId(state, { id }) {
    state.accessTokenIntervalId = id;
  },
  updateRefreshFailCount(state, { count }) {
    state.refreshFailCount = count;
  },
  setAvailableRoles(state, roles) {
    state.roles = roles;
  },
  updateSelectedRole(state, roleId) {
    state.selectedRoleId = roleId;
  },
  setQrData(state, qrData) {
    state.qrData = qrData;
  },
  setLoggedInUserData(state, user) {
    state.user.id = user.id;
    state.user.email = user.email;
    state.user.firstName = user.firstName;
    state.user.lastName = user.lastName;
    state.user.avatar = user.avatar;
    state.user.loggedIn = user.loggedIn;
    state.user.roles = user.roles;
    state.user.permissions = user.permissions;
    if (user.expiresIn === null) {
      localStorage.removeItem('tokenExpiresAt');
    } else if (user.expiresIn) {
      // expiresIn is expressed in seconds
      localStorage.setItem('tokenExpiresAt', Date.now() + (parseInt(user.expiresIn, 10) * 1000));
    }
  },
  setAllUsers(state, users) {
    state.users = users;
  },
  updateSelectedUser(state, user) {
    state.selectedUser.id = user.id;
    state.selectedUser.firstName = user.firstName;
    state.selectedUser.lastName = user.lastName;
    state.selectedUser.email = user.email;
    state.selectedUser.avatar = user.avatar;
    state.selectedUser.roles = user.roles;
    state.selectedUser.roleNames = user.roleNames;
    state.selectedUser.clientIds = user.clientIds;
  },
  clearSelectedUser(state, payload) {
    state.selectedUser.id = null;
    state.selectedUser.firstName = '';
    state.selectedUser.lastName = '';
    state.selectedUser.email = '';
    state.selectedUser.avatar = '';
    state.selectedUser.roles = [];
    state.selectedUser.roleNames = [];
    state.selectedUser.clientIds = [];
  },
  setUserAvatar(state, { id, data }) {
    const item = state.avatars.find((i) => i.userId === id);
    if (!item) {
      state.avatars.push({ userId: id, img: data });
    } else {
      item.img = data;
    }
  },
  setShiftData(state, shiftData) {
    state.shiftData.slot = shiftData.slot;
    state.shiftData.handover = shiftData.handover;
    state.shiftData.contract = shiftData.contract;
    state.shiftData.trial = shiftData.trial;
    state.shiftData.pov = shiftData.pov;
  },
  setXsrfToken(state, xsrfToken) {
    state.xsrfToken = xsrfToken;
  },
};
