import fileDownload from 'js-file-download';
import { axiosGet, axiosPatch } from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const RETRIEVE_ASM_APPLICATIONS = 'retrieving ASM applications.';
const RETRIEVE_ASM_DOMAINS = 'retrieving ASM domains.';
const RETRIEVE_ASM_FQDNS = 'retrieving ASM fqdns.';
const RETRIEVE_ASM_IPS = 'retrieving ASM ips.';
const RETRIEVE_ASM_NETBLOCKS = 'retrieving ASM netblocks.';
const EXPORT_ASM_APPLICATIONS_CSV = 'exporting ASM data to CSV.';
const EXPORT_ASM_APPLICATION_PDF = 'exporting ASM application data to PDF.';
const EXPORT_ASM_DOMAIN_PDF = 'exporting ASM domain data to PDF.';
const EXPORT_ASM_FQDN_PDF = 'exporting ASM fqdn data to PDF.';
const EXPORT_ASM_IP_PDF = 'exporting ASM ip data to PDF.';
const EXPORT_ASM_NETBLOCK_PDF = 'exporting ASM netblock data to PDF.';
const CONFIRM_ASM_DOMAIN = 'confirming ASM domain.';
const UNCONFIRM_ASM_DOMAIN = 'unconfirming ASM domain.';
const ARCHIVE_ASM_DOMAIN = 'archiving ASM domain.';
const CONFIRM_ASM_NETBLOCK = 'confirming ASM netblock.';
const UNCONFIRM_ASM_NETBLOCK = 'unconfirming ASM netblock.';
const ARCHIVE_ASM_NETBLOCK = 'archiving ASM netblock.';

const formatTimeLocalWithoutDelimiters = (date = new Date()) => {
  date = new Date(date);
  const zeroFill = (number) => { return String(number).length === 1 ? `0${number}` : number; };
  return `${date.getFullYear()}${zeroFill(date.getMonth() + 1)}${zeroFill(date.getDate())}_`
    + `${zeroFill(date.getHours())}${zeroFill(date.getMinutes())}${zeroFill(date.getSeconds())}`;
};

const formatPdfFilename = (type, id, clientName) => {
  return `asm_${type}_${id}_${clientName.replace(/[^0-9a-z]/gi, '')}_${formatTimeLocalWithoutDelimiters()}.pdf`;
};

const formatAsmObjectsQuery = (baseUrl, payload) => {
  return `${baseUrl}?querystringenabled=1&limit=${payload.limit || 50}`
  + `&client_id=${payload.clientId || 0}&state=${payload.state || 1}&withrisks=${payload.withrisks || 0}`;
};

export default {
  /** Begin AsmApplication actions. */
  async getAllAsmApplications({ commit, getters }, payload) {
    return axiosGet(formatAsmObjectsQuery(`${getters.apiPrefix}/asmapplications`, payload), {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ASM_APPLICATIONS}`);
      commit('mergeAsmApplications', res.data);
    }).catch((err) => console.log(err.message));
  },
  async exportAssetsCSV({ commit, getters }, { clientId, clientName, vm }) {
    return axiosGet(`${getters.apiPrefix}/asmapplications/exportcsv/${clientId}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${EXPORT_ASM_APPLICATIONS_CSV}`);
      return fileDownload(res.data, `${clientName.replace(/[^0-9a-z]/gi, '')}_assets_${formatTimeLocalWithoutDelimiters()}.csv`);
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async exportAsmApplicationPDF({ commit, getters }, { asmObject, vm }) {
    return axiosGet(`${getters.apiPrefix}/asmapplications/exportpdf/${asmObject.uid}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${EXPORT_ASM_APPLICATION_PDF}`);
      return fileDownload(res.data, formatPdfFilename('application', asmObject.application_id, asmObject.client.name));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  /** End AsmApplication actions. */

  /** Begin AsmDomain actions. */
  async getAllAsmDomains({ commit, getters }, payload) {
    return axiosGet(formatAsmObjectsQuery(`${getters.apiPrefix}/asmdomains`, payload), {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ASM_DOMAINS}`);
      commit('mergeAsmDomains', res.data);
    }).catch((err) => console.log(err.message));
  },
  async exportAsmDomainPDF({ commit, getters }, { asmObject, vm }) {
    return axiosGet(`${getters.apiPrefix}/asmdomains/exportpdf/${asmObject.uid}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${EXPORT_ASM_DOMAIN_PDF}`);
      return fileDownload(res.data, formatPdfFilename('domain', asmObject.assetId, asmObject.client.name));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async confirmAsmDomain({ dispatch, getters }, { uid, vm }) {
    return axiosPatch(`${getters.apiPrefix}/asmdomains/confirm/${uid}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CONFIRM_ASM_DOMAIN}`);
      dispatch('getAllAsmDomains');
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async unconfirmAsmDomain({ dispatch, getters }, { uid, vm }) {
    return axiosPatch(`${getters.apiPrefix}/asmdomains/unconfirm/${uid}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UNCONFIRM_ASM_DOMAIN}`);
      dispatch('getAllAsmDomains');
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async archiveAsmDomain({ dispatch, getters }, { uid, vm }) {
    return axiosPatch(`${getters.apiPrefix}/asmdomains/archive/${uid}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ARCHIVE_ASM_DOMAIN}`);
      dispatch('getAllAsmDomains');
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  /** End AsmDomain actions. */

  /** Begin AsmFqdn actions. */
  async getAllAsmFqdns({ commit, getters }, payload) {
    return axiosGet(formatAsmObjectsQuery(`${getters.apiPrefix}/asmfqdns`, payload), {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ASM_FQDNS}`);
      commit('mergeAsmFqdns', res.data);
    }).catch((err) => console.log(err.message));
  },
  async exportAsmFqdnPDF({ commit, getters }, { asmObject, vm }) {
    return axiosGet(`${getters.apiPrefix}/asmfqdns/exportpdf/${asmObject.uid}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${EXPORT_ASM_FQDN_PDF}`);
      return fileDownload(res.data, formatPdfFilename('fqdn', asmObject.assetId, asmObject.client.name));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  /** End AsmFqdn actions. */

  /** Begin AsmIp actions. */
  async getAllAsmIps({ commit, getters }, payload) {
    return axiosGet(formatAsmObjectsQuery(`${getters.apiPrefix}/asmips`, payload), {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ASM_IPS}`);
      commit('mergeAsmIps', res.data);
    }).catch((err) => console.log(err.message));
  },
  async exportAsmIpPDF({ commit, getters }, { asmObject, vm }) {
    return axiosGet(`${getters.apiPrefix}/asmips/exportpdf/${asmObject.uid}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${EXPORT_ASM_IP_PDF}`);
      return fileDownload(res.data, formatPdfFilename('ip', asmObject.assetId, asmObject.client.name));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  /** End AsmIp actions. */

  /** Begin AsmNetblock actions. */
  async getAllAsmNetblocks({ commit, getters }, payload) {
    return axiosGet(formatAsmObjectsQuery(`${getters.apiPrefix}/asmnetblocks`, payload), {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_ASM_NETBLOCKS}`);
      commit('mergeAsmNetblocks', res.data);
    }).catch((err) => console.log(err.message));
  },
  async exportAsmNetblockPDF({ commit, getters }, { asmObject, vm }) {
    return axiosGet(`${getters.apiPrefix}/asmnetblocks/exportpdf/${asmObject.uid}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${EXPORT_ASM_NETBLOCK_PDF}`);
      return fileDownload(res.data, formatPdfFilename('netblock', asmObject.assetId, asmObject.client.name));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async confirmAsmNetblock({ dispatch, getters }, { uid, vm }) {
    return axiosPatch(`${getters.apiPrefix}/asmnetblocks/confirm/${uid}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CONFIRM_ASM_NETBLOCK}`);
      dispatch('getAllAsmNetblocks');
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async unconfirmAsmNetblock({ dispatch, getters }, { uid, vm }) {
    return axiosPatch(`${getters.apiPrefix}/asmnetblocks/unconfirm/${uid}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UNCONFIRM_ASM_NETBLOCK}`);
      dispatch('getAllAsmNetblocks');
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async archiveAsmNetblock({ dispatch, getters }, { uid, vm }) {
    return axiosPatch(`${getters.apiPrefix}/asmnetblocks/archive/${uid}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ARCHIVE_ASM_NETBLOCK}`);
      dispatch('getAllAsmNetblocks');
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  /** End AsmNetblock actions. */
};
