<template>
  <nav class="navbar navbar-expand-lg navbar-dark text-light">
    <a href="/soc"><img
      class="dt-icon"
      src="@/assets/img/new-dt-logo.svg"
    ></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>

    <div
      id="navbarSupportedContent"
      class="collapse navbar-collapse"
    >
      <ul class="navbar-nav w-100 mr-auto d-flex flex-row-reverse">
        <li
          v-if="canAccessGeneralConfig"
          class="nav-item order-2"
        >
          <a
            class="nav-link"
            href="#"
          >
            <font-awesome-icon icon="cog" />
          </a>
        </li>
        <li class="nav-item dropdown order-1">
          <a
            id="navbarDropdown"
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ loggedInUser }}
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            <a
              class="dropdown-item text-white"
              href="/profile"
            >
              Profile
            </a>

            <div
              v-if="loggedInUserIsAdmin"
              class="dropdown-divider"
            />

            <a
              v-if="loggedInUserIsAdmin"
              class="dropdown-item text-white"
              href="/admin"
            >
              Admin
            </a>

            <div class="dropdown-divider" />

            <a
              class="dropdown-item text-white"
              href="/admin/clients"
            >
              Client Management
            </a>

            <div class="dropdown-divider" />

            <a
              class="dropdown-item text-white"
              href="/asm"
            >
              Attack Surface Management
            </a>

            <div class="dropdown-divider" />

            <a
              class="dropdown-item text-white"
              href="/respondemail/summary"
            >
              Email Overview
            </a>

            <div class="dropdown-divider" />

            <a
              class="dropdown-item text-white"
              href="/respondemail/logs"
            >
              Email Logs
            </a>

            <div
              v-if="loggedInUserIsAdmin"
              class="dropdown-divider"
            />

            <a
              v-if="loggedInUserIsAdmin"
              class="dropdown-item text-white"
              href="/admin/roles"
            >
              Scoped Roles
            </a>

            <div class="dropdown-divider" />

            <a
              class="dropdown-item text-white"
              href="/querylogs"
            >
              Query Logs
            </a>

            <div class="dropdown-divider" />

            <a
              class="dropdown-item text-white"
              href="#"
              @click="submitLogOut"
            >
              Log Out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    loggedInUserIsAdmin() {
      return this.$store.getters.user.roles.indexOf('admin') !== -1
        || this.$store.getters.user.roles.indexOf('defaultAdmin') !== -1;
    },
    loggedInUser() {
      return this.$store.getters.loggedInUser('userName');
    },
    canAccessGeneralConfig() {
      return this.$store.getters.checkPermission({ method: 'get', resource: 'config' });
    },
  },
  methods: {
    submitLogOut() {
      this.$store.dispatch('logout')
        .then((result) => result && this.$router.push('/loggedout', () => {}))
        .catch(() => {
          this.$swal({
            title: 'Sorry!',
            text: 'An error occurred while trying to log out',
            icon: 'error',
          });
        });
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #111111;
  border-bottom: 1px solid grey;
}
.dt-icon { height: 4vh; }
.dropdown-menu {
  background-color: #111111;
  border: 1px solid grey;
  margin-right: 5%;
  width: 95%;
  min-width: 14rem;
}
.dropdown-item:hover, .dropdown-item:focus {
  text-decoration: underline;
  background-color: transparent;
}
.dropdown-divider {
  width: 90%;
  margin-left: 5%;
}
.dropdown-item {
  padding: 0;
  text-align: center;
}
</style>
