/* eslint-disable func-names */
/* eslint-disable no-extend-native */
/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueClipboard from 'vue-clipboard2';
import VTooltip from 'v-tooltip';
import axios from 'axios';
import '@/assets/css/global.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCog,
  faLock,
  faPencilAlt,
  faPlus,
  faStar,
  faTimes,
  faTrashAlt,
  faUser,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App.vue';
import { getUpdatedAccessToken } from './requests';
import router from './router';
import store from './store';
// eslint-disable-next-line import/no-named-as-default
import PerfectScrollbar from './components/scrollbar';
import './assets/css/perfect-scrollbar.css';

library.add(
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCog,
  faLock,
  faPencilAlt,
  faPlus,
  faStar,
  faTimes,
  faTrashAlt,
  faUser,
  faTag,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueLodash, { lodash });
Vue.use(VueClipboard);
Vue.use(VTooltip);
Vue.use(PerfectScrollbar);

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

String.prototype.substringOf = function (string) {
  return string.toLowerCase().indexOf(this.toString().toLowerCase()) !== -1;
};
String.prototype.capitalized = function () {
  return `${this.toString().charAt(0).toUpperCase()}${this.toString().slice(1).toLowerCase()}`;
};

new Vue({
  router,
  store,
  watch: {
    $route(to) {
      window.document.title = to.meta.title || 'SOC';
    },
  },
  created() {
    if (localStorage.getItem('tokenExpiresAt')) this.$store.dispatch('refreshUserDetails', {});
    store.commit('updateAccessTokenIntervalId',
      { id: setInterval(() => getUpdatedAccessToken({ store, router }), 30000) });
  },
  methods: {
    clamp(value, min, max) {
      return Math.min(Math.max(value || min, min), max);
    },
    formatDevice(device, identifiers = ['identifier', 'devicelabel', 'hostname', 'ip', 'mac', 'os', 'typelabel', 'vendor']) {
      if (typeof device === 'number') return `Did: ${device}`;
      if (typeof device === 'string') device = JSON.parse(device);
      // eslint-disable-next-line security/detect-object-injection
      return `${identifiers.filter((i) => device[i] && device[i].length).map((i) => device[i]).join(' - ')}`;
    },
    formatStatus: (statusString) => {
      if (!statusString) return '';
      statusString = statusString.toString();
      switch (statusString) {
        case 'inprogress': return 'In Progress';
        case 'alertraised': return 'Alert Raised';
        default: return statusString.capitalized();
      }
    },
    formatTime: (time) => {
      if (!time) return '';
      const [datePart, timePart] = new Date(time).toISOString().split('T');
      return `${datePart} ${timePart.split('.')[0]} UTC`;
    },
    parseHeaderFrom: (email) => {
      return email ? `${email.header_from_personal || ''} ${email.header_from_email
        ? `<${email.header_from_email}>`
        : email.header_from || ''}` : '';
    },
    parseRecipients: (respondEmail) => {
      // Allow at most 70 characters, then indicate how many recipients are hidden.
      if (!respondEmail || !respondEmail.rcpts || !respondEmail.rcpts.length) {
        return '';
      }
      let leftovers = 0;
      return respondEmail.rcpts.slice(1).reduce((string, rcpt, index, array) => {
        if (`${string}, ${rcpt.rcpt_to}`.length < 70) {
          return `${string}, ${rcpt.rcpt_to}`;
        }
        if (leftovers) {
          return string;
        }
        leftovers = array.length - index;
        return `${string} (+ ${leftovers} other${leftovers > 1 ? 's' : ''})`;
      }, respondEmail.rcpts[0].rcpt_to);
    },
    parseRecipientTags: (rcpts) => {
      const tags = rcpts.map((rcpt) => rcpt.tags.map((tag) => tag.name)).flat(1);
      return tags.filter((tag, i) => tags.indexOf(tag) === i).join(', ');
    },
    safelyReadValue: (object, key) => {
      // eslint-disable-next-line security/detect-object-injection
      return object && {}.hasOwnProperty.call(object, key) ? object[key] : '';
    },
    trimmedUid(uid) {
      return uid ? uid.slice(0, 8) : '';
    },
    /**
     * @param {String} newPass
     * @param {String} newPassConf
     * @param {Boolean} requireOldPass default false
     * @param {String} oldPass default null
     * @returns {String} empty string if new password passes all requirements,
     *                   or a string describing a problem.
     */
    errorWithPassword(newPass, newPassConf, requireOldPass = false, oldPass = null) {
      if (!newPass) return 'Please provide a new password.';
      if (!newPassConf) return 'Please retype your new password to confirm.';
      if (newPass !== newPassConf) return 'New password confirmation did not match.';
      if (requireOldPass) {
        if (!oldPass) return 'Please provide your current password.';
        if (oldPass === newPass) return 'New password must differ from current password.';
      }
      return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{12,}/.test(newPass) ? ''
        : 'New password must have a minimum length of 12 characters, including at least '
        + 'one number, one lowercase and uppercase letter, and one special character.';
    },
    isValidUUID: (string) => {
      return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(string);
    },
    textContainsPublicIp: (text) => {
      return /(\d+)(?<!10)(?<!127)\.(\d+)(?<!192\.168)(?<!172\.(1[6-9]|2\d|3[0-1]))\.(\d+)\.(\d+)/
        // eslint-disable-next-line no-useless-escape
        .test(text.replace(/[\[\]\(\)\{\}]/gi, ''));
    },
    // Dispatches the given action. Then, if it was successful, closes the modal.
    async dispatchCloseModal(action, payload) {
      return store.dispatch(action, payload)
        .then((result) => { return result && payload.vm.closeModal(false); });
    },
    // Dispatches the given action, displays the returned message as
    // a 'Success!' alert, then calls the given callback function.
    async dispatchDisplaySuccess(action, payload, callback = null) {
      return store.dispatch(action, payload)
        .then((message) => { return message && this.successSwal(message, callback); });
    },
    // Dispatches the given action, displays the returned message
    // as a 'Success!' alert, then closes the originating modal.
    async dispatchDisplaySuccessCloseModal(action, payload) {
      return this.dispatchDisplaySuccess(action, payload, () => payload.vm.closeModal());
    },
    errorSwal(message, callback = null) {
      this.$swal({
        title: 'Error',
        // Use lodash get() to find response.data.message or default to message.
        text: this._.get(message, 'response.data.message', message),
        icon: 'error',
      }).then(callback);
    },
    async confirmSwal(title, text, confirmButtonText, callback) {
      return this.$swal({
        title,
        text,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText,
      }).then((input) => { return input.isConfirmed ? callback() : false; });
    },
    successSwal(message, callback = null) {
      this.$swal({ title: 'Success!', text: message, icon: 'success' }).then(callback);
    },
    warningSwal(message, callback = null) {
      this.$swal({
        title: 'Warning',
        // Use lodash get() to find response.data.message or default to message.
        text: this._.get(message, 'response.data.message', message),
        icon: 'warning',
      }).then(callback);
    },
  },
  render: (h) => h(App),
}).$mount('#app');
