import {
  axiosGet,
  axiosPost,
  axiosPatch,
} from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const RETRIEVE_PTN = 'retrieving PTN data.';
const CLAIM = 'claiming breach.';
const UPDATE = 'updating breach.';
const REFRESH = 'refreshing breach';
const CREATE_COMMENT = 'creating breach comment.';
const RESOLVE = 'resolving breach.';
const ALERT = 'alerting breach.';
const ACKNOWLEDGE = 'acknowledging breach.';
const UNACKNOWLEDGE = 'unacknowledging breach.';
const ASSIGN = 'assigning breach.';

export default {
  async getNewestBreaches({ getters, commit }, payload) {
    let uri = `${getters.breachApi()}&limitactive=49&limitinactive=1`;
    if (getters.selectedClientId) uri += `&clientid=${getters.selectedClientId}`;
    return axiosGet(uri, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_PTN}`);
      console.log(`Retrieved ${res.data.length} newest model breaches from backend`);
      commit('mergeSocObjectBreaches', res.data);
    }).catch((err) => console.log(err.message));
  },
  // Retrieve and merge a batch of 50 active and/or inactive Breach objects, using the status,
  // score, and selected client as query parameters. Offset the query by the number of breaches
  // in the store which match the query parameters we're sending.
  async getQueryFilteredBreaches({ getters, commit }, payload) {
    let uri = `${getters.breachApi()}&minscore=${getters.minSocObjectScore || 0}&maxscore=${getters.maxSocObjectScore || 100}`
      + `&offsetactive=${getters.socObjectsFilteredByQueryableParams('breach', true).length}`
      + `&offsetinactive=${getters.socObjectsFilteredByQueryableParams('breach', false).length}`;
    if (getters.selectedClientId) uri += `&clientid=${getters.selectedClientId}`;
    if (getters.socObjectStartDate) uri += `&start=${new Date(getters.socObjectStartDate).getTime()}`;
    if (getters.socObjectEndDate) uri += `&end=${new Date(getters.socObjectEndDate).getTime()}`;
    switch (getters.selectedStatusFilterId) {
      case 1: case 2: case 6: uri += '&limitactive=50&limitinactive=1'; break; // Active
      case 3: case 4: case 7: uri += '&limitactive=1&limitinactive=50'; break; // Terminal
      default: uri += '&limitactive=50&limitinactive=50'; break; // All statuses
    }
    return axiosGet(uri, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_PTN}`);
      console.log(`Retrieved ${res.data.length} query-filtered model breaches from backend`);
      commit('mergeSocObjectBreaches', res.data);
    }).catch((err) => console.log(err.message));
  },
  async getAllBreaches({ commit, getters }, payload) {
    return axiosGet(`${getters.breachApi()}&limit=100`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_PTN}`);
      commit('mergeSocObjectBreaches', res.data);
      const first = getters.filteredAndOrderedSocObjects[0];
      if (first) commit('setAsSelectedSocObject', { uid: first.uid, type: first.type });
    }).catch((err) => console.log(err.message));
  },
  async getClientBreaches({ commit, getters }, { clientId }) {
    return axiosGet(`${getters.apiPrefix}/breaches/client/${clientId}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_PTN}`);
      commit('mergeSocObjectBreaches', res.data);
      const first = getters.filteredAndOrderedSocObjects[0];
      if (first) commit('setAsSelectedSocObject', { uid: first.uid, type: first.type });
    }).catch((err) => console.log(err.message));
  },
  async claimBreach({ dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/claim`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CLAIM}`);
      dispatch('refreshBreach', { breachUid: postFields.breachUid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async checkAndClaimBreach({ commit, dispatch, getters }, { vm }) {
    const postFields = { breachUid: getters.selectedSocObject.uid };
    return axiosGet(`${getters.apiPrefix}/breaches/${getters.selectedSocObject.uid}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CLAIM}`);
      return res.data.assigneeId === null || (res.data.assigneeId !== undefined
          && res.data.assigneeId === getters.loggedInUser('id'))
        ? dispatch('claimBreach', { postFields, vm })
        : vm.$root.confirmSwal('Overwrite Assignment',
          'This breach was already claimed by another user',
          'Assign me anyway!',
          () => dispatch('claimBreach', { postFields, vm }));
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async updateBreachDetails({ dispatch, getters }, { postFields, vm }) {
    return axiosPatch(`${getters.apiPrefix}/breaches/${getters.selectedSocObject.uid}`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE}`);
      dispatch('refreshBreach', { breachUid: getters.selectedSocObject.uid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async refreshBreach({ commit, getters }, { breachUid, vm }) {
    return breachUid && vm.$root.isValidUUID(breachUid)
    && axiosGet(`${getters.apiPrefix}/breaches/${breachUid}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${REFRESH}`);
      if (res.data.id) commit('updateBreachByUid', res.data);
    }).catch((error) => console.log(error.message));
  },
  async createNewBreachComment({ commit, dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/breachcomments`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_COMMENT}`);
      dispatch('refreshBreach', { breachUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedBreachComment', { uid: getters.selectedSocObject.uid, type: 'regular' });
      return true;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async resolveBreach({ commit, dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/resolve`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RESOLVE}`);
      dispatch('refreshBreach', { breachUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedBreachComment', { uid: getters.selectedSocObject.uid, type: 'resolve' });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async alertBreach({ commit, dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/alert`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ALERT}`);
      dispatch('refreshBreach', { breachUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedBreachComment', { uid: getters.selectedSocObject.uid, type: 'alert' });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async emailAlertBreach({ commit, dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/alert/email`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ALERT}`);
      dispatch('refreshBreach', { breachUid: getters.selectedSocObject.uid, vm });
      commit('clearSavedBreachComment', { uid: getters.selectedSocObject.uid, type: 'alert' });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async acknowledgeBreach({ dispatch, getters }, { breachUid, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/acknowledge`, { breachUid }, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ACKNOWLEDGE}`);
      dispatch('refreshBreach', { breachUid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async unacknowledgeBreach({ dispatch, getters }, { breachUid, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/unacknowledge`, { breachUid }, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UNACKNOWLEDGE}`);
      dispatch('refreshBreach', { breachUid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
  async assignBreach({ dispatch, getters }, { postFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/breaches/assign`, postFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ASSIGN}`);
      dispatch('refreshBreach', { breachUid: postFields.breachUid, vm });
      return res.data.message;
    }).catch((error) => vm.$root.errorSwal(error));
  },
};
