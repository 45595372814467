import {
  axiosGet,
  axiosPost,
  axiosPatch,
  axiosDelete,
} from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const CREATE_CLIENT = 'creating a new client.';
const UPDATE_CLIENT = 'updating client details.';
const RETRIEVE_CLIENTS = 'retrieving client data.';
const DELETE_CLIENT = 'deleting client.';
const CREATE_CONTACT = 'creating a new client contact.';
const UPDATE_CONTACT = 'updating client contact details.';
const DELETE_CONTACT = 'deleting client contact.';
const CREATE_BOX = 'creating a new box.';
const UPDATE_BOX = 'updating box details.';
const DELETE_BOX = 'deleting box.';
const RETRIEVE_QUERY_LOG = 'retrieving query log data.';

export default {
  async createNewClient({ dispatch, getters }, { clientObject, vm }) {
    return axiosPost(`${getters.apiPrefix}/clients`, clientObject, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_CLIENT}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateClientDetails({ dispatch, getters }, { updateFields, vm }) {
    return axiosPatch(`${getters.apiPrefix}/clients/${updateFields.id}`, updateFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_CLIENT}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async getAllClients({ commit, getters }, payload) {
    return axiosGet(`${getters.apiPrefix}/clients`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_CLIENTS}`);
      commit('setClients', res.data);
    }).catch((err) => console.log(err.message));
  },
  async deleteClient({ dispatch, getters }, { id, vm }) {
    return axiosDelete(`${getters.apiPrefix}/clients/${id}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${DELETE_CLIENT}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async createNewClientContact({ dispatch, getters }, { clientContactObject, vm }) {
    return axiosPost(`${getters.apiPrefix}/clientcontacts`, clientContactObject, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_CONTACT}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateClientContactDetails({ dispatch, getters }, { updateFields, vm }) {
    return axiosPatch(`${getters.apiPrefix}/clientcontacts/${updateFields.id}`, updateFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_CONTACT}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async deleteClientContact({ dispatch, getters }, { id, vm }) {
    return axiosDelete(`${getters.apiPrefix}/clientcontacts/${id}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${DELETE_CONTACT}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async createNewBox({ dispatch, getters }, { boxObject, vm }) {
    return axiosPost(`${getters.apiPrefix}/boxes`, boxObject, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_BOX}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateBoxDetails({ dispatch, getters }, { updateFields, vm }) {
    return axiosPatch(`${getters.apiPrefix}/boxes/${updateFields.id}`, updateFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_BOX}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async deleteBox({ dispatch, getters }, { id, vm }) {
    return axiosDelete(`${getters.apiPrefix}/boxes/${id}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${DELETE_BOX}`);
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async getQueryLogs({ commit, getters }, { limit }) {
    return axiosGet(`${getters.apiPrefix}/querylogs/all?querystringenabled=1&limit=${limit || 50}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RETRIEVE_QUERY_LOG}`);
      return commit('setQueryLogs', res.data);
    }).catch((err) => console.log(err.message));
  },
};
