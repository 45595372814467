import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

// Returns false only if we find a required permission the user doesn't have.
const hasAllPermissions = (permissionsRequired, userPermissions) => {
  return !permissionsRequired.find((perm) => userPermissions.indexOf(perm) === -1);
};

// Return true if we find at least one required role the user has.
const hasAtLeastOneRole = (rolesRequired, userRoles) => {
  return rolesRequired.find((role) => userRoles.indexOf(role) !== -1);
};

const routes = [{
  path: '/',
  redirect: '/soc',
}, {
  path: '/404',
  name: 'Page404',
  component: () => import('../views/404.vue'),
}, {
  path: '/soc/:socObjectUid?',
  name: 'Breaches',
  component: () => import('../views/Soc.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'SOC Dashboard',
    permissions: ['breaches', 'breaches/soc'],
  },
}, {
  path: '/login',
  name: 'Login',
  component: () => import('../views/Login.vue'),
  meta: {
    title: 'Login',
    permissions: [],
  },
}, {
  path: '/activate/:needsPassword/:token',
  name: 'Activate Account',
  component: () => import('../views/Activate.vue'),
  meta: {
    title: 'Activate Account',
    permissions: [],
  },
}, {
  path: '/forgotpassword/:token',
  name: 'Forgot Password',
  component: () => import('../views/ForgotPassword.vue'),
  meta: {
    title: 'Forgot Password',
    permissions: [],
  },
}, {
  path: '/profile',
  name: 'Profile',
  component: () => import('../views/Profile.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Profile',
    permissions: ['users/my-data'],
  },
}, {
  path: '/admin',
  name: 'Admin',
  component: () => import('../views/Admin.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAtLeastOneRole(to.meta.roles, store.state.user.roles)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Admin',
    roles: ['admin', 'defaultAdmin'],
  },
}, {
  path: '/admin/clients',
  name: 'Clients',
  component: () => import('../views/Clients.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Clients',
    permissions: ['clients'],
  },
}, {
  path: '/asm',
  name: 'Attack Surface Management',
  component: () => import('../views/ASM.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'ASM',
    permissions: ['asmapplications'],
  },
}, {
  path: '/querylogs',
  name: 'Query Logs',
  component: () => import('../views/QueryLogs.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Query Logs',
    permissions: ['querylogs/all'],
  },
}, {
  path: '/respondemail/logs/:clientId?',
  name: 'Darktrace/Email Logs',
  component: () => import('../views/RespondEmailLogs.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Email Logs',
    permissions: ['respondemails'],
  },
}, {
  path: '/respondemail/summary/:clientId?',
  name: 'Darktrace/Email Overview',
  component: () => import('../views/RespondEmailSummary.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAllPermissions(to.meta.permissions, store.state.user.permissions.get)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Email Statistics',
    permissions: ['respondemails'],
  },
}, {
  path: '/admin/roles',
  name: 'Roles',
  component: () => import('../views/Roles.vue'),
  beforeEnter: (to, from, next) => {
    if (hasAtLeastOneRole(to.meta.roles, store.state.user.roles)) next();
    else next({ path: '/404' });
  },
  meta: {
    title: 'Roles',
    roles: ['admin', 'defaultAdmin'],
  },
}, {
  path: '/loggedout',
  name: 'LoggedOut',
  component: () => import('../views/LoggedOut.vue'),
  meta: {
    title: 'Logged Out',
    permissions: [],
  },
}, {
  path: '/404',
  name: '404NotFound',
}, {
  path: '*',
  redirect: '/404',
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let loggedIn = store.getters.loggedInWithValidToken();
  // If the user is not logged in but we have a refresh_token cookie (the presence of which is
  // indicated by localStorage.tokenExpiresAt), try using it to refresh the user's details.
  if (!loggedIn) {
    const expiresAt = localStorage.getItem('tokenExpiresAt');
    if (expiresAt && Date.now() < parseInt(expiresAt, 10)) {
      await store.dispatch('refreshUserDetails', {});
    }
  }
  loggedIn = store.getters.loggedInWithValidToken();

  // If this is a password reset link or an activation link, return next, but only if NOT logged in.
  if (to.path.match(/forgotpassword\/.+/) || to.path.match(/activate\/[01]\/.+/)) {
    if (loggedIn) {
      next('/login');
    } else {
      next();
    }
  } else if (to.path === '/login' && loggedIn) {
    next('/soc');
  } else if (['/login', '/loggedout'].indexOf(to.path) === -1 && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
