import PerfectScrollbar from 'perfect-scrollbar';

const perfectScrollbar = {
  name: 'PerfectScrollbar',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    watchOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ps: null,
    };
  },
  watch: {
    watchOptions(shouldWatch) {
      return !shouldWatch && this.watcher
        ? this.watcher()
        : this.createWatcher();
    },
  },
  mounted() {
    this.create();
    return this.watchOptions && this.createWatcher();
  },
  updated() {
    this.$nextTick(() => this.update());
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    create() {
      if (!(this.ps && this.$isServer)) {
        this.ps = new PerfectScrollbar(this.$refs.container, this.options);
      }
    },
    createWatcher() {
      this.watcher = this.$watch('options', () => {
        this.destroy();
        this.create();
      }, { deep: true });
    },
    update() {
      return this.ps && this.ps.update();
    },
    destroy() {
      if (this.ps) {
        this.ps.destroy();
        this.ps = null;
      }
    },
  },
  render(h) {
    return h(this.tag, {
      ref: 'container',
      class: 'ps',
      on: this.$listeners,
    },
    this.$slots.default);
  },
};

export function install(Vue, settings) {
  if (settings) {
    if (settings.name && typeof settings.name === 'string') {
      perfectScrollbar.name = settings.name;
    }
    if (settings.options && typeof settings.options === 'object') {
      perfectScrollbar.props.options.default = () => settings.options;
    }
    if (settings.tag && typeof settings.tag === 'string') {
      perfectScrollbar.props.tag.default = settings.tag;
    }
    if (settings.watchOptions && typeof settings.watchOptions === 'boolean') {
      perfectScrollbar.props.watchOptions = settings.watchOptions;
    }
  }
  Vue.component(perfectScrollbar.name, perfectScrollbar);
}

export { perfectScrollbar };
export default install;
