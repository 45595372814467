import axios from 'axios';

axios.defaults.withCredentials = true;

export const getUpdatedAccessToken = async ({ store, router }) => {
  // If the access token is within one minute of expiry, use the refresh token to get a new one.
  const expiresAt = localStorage.getItem('tokenExpiresAt');
  return expiresAt && Date.now() >= parseInt(expiresAt, 10) - 60000
    && axios.post(`${store.getters.apiPrefix}/refresh_token`)
      .then((res) => {
        if (!res.data) throw new Error('No token data returned.');
        else if (res.data.error) {
          store.commit('updateRefreshFailCount', { count: store.state.refreshFailCount + 1 });
          // 5 refresh failures results in complete logout
          if (store.state.refreshFailCount > 5) {
            console.log(`Dispatching logout. ${store.state.refreshFailCount}`);
            return store.dispatch('logout')
              .then((logoutRes) => logoutRes && router.push('/loggedout', () => {}))
              .catch((err) => console.log(err.message));
          }
        } else if (res.data.expiresIn) {
          localStorage.setItem('tokenExpiresAt', Date.now() + (parseInt(res.data.expiresIn, 10) * 1000));
          store.commit('updateRefreshFailCount', { count: 0 });
          store.commit('setXsrfToken', res.headers['x-xsrf-token']);
        }
        return res.data.expiresIn;
      }).catch((error) => console.log(error.message));
};

export const axiosGet = async (url, options = {}, commit) => {
  const headers = options.headers === undefined ? {} : { ...options.headers };
  return axios.get(url, { ...options }, { headers })
    .then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.message);
      commit('setXsrfToken', res.headers['x-xsrf-token'] || res.headers['X-XSRF-TOKEN']);
      return res;
    });
};

export const axiosPost = async (url, options = {}, getters) => {
  const headers = options.headers === undefined ? {} : { ...options.headers };
  headers['X-XSRF-TOKEN'] = getters.xsrfToken;
  return axios.post(url, { ...options }, { headers })
    .then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.message);
      return res;
    });
};

export const axiosPatch = async (url, options = {}, getters) => {
  const headers = options.headers === undefined ? {} : { ...options.headers };
  headers['X-XSRF-TOKEN'] = getters.xsrfToken;
  return axios.patch(url, { ...options }, { headers })
    .then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.message);
      return res;
    });
};

export const axiosDelete = async (url, options = {}, getters) => {
  const headers = options.headers === undefined ? {} : { ...options.headers };
  headers['X-XSRF-TOKEN'] = getters.xsrfToken;
  // Delete actions should have no request body, only headers
  return axios.delete(url, { headers })
    .then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.message);
      return res;
    });
};
