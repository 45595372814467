import axios from 'axios';
import {
  axiosPost,
} from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const ACTIVATE = 'activating user account.';
const LOGIN = 'logging in. No token data returned.';
const LOGOUT = 'logging out. No response data.';
const SETUP_TOTP = 'retrieving QR data.';
const FORGOT_PASSWORD = 'attempting to send a password reset link.'; // njsscan-ignore: node_password
const UPDATE_PASSWORD_LINK = 'attempting to update password from a "forgot my password" link.'; // njsscan-ignore: node_password

export default {
  async activateAccount({ commit, getters }, { token, password, vm }) {
    const body = (password && password.length) ? { password } : {};
    return axios.post(`${getters.apiPrefix}/activate/${token}`, body).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${ACTIVATE}`);
      commit('setQrData', res.data.data);
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async login({ commit, getters }, { email, password, vm }) {
    const requiredLoginFields = [
      'expiresIn',
      'id',
      'email',
      'firstName',
      'lastName',
      'roles',
      'permissions',
      'avatar',
      'twoFactorRequired',
    ];
    return axios.post(`${getters.apiPrefix}/login`, { email, password }).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${LOGIN}`);
      if (res.data.error) throw new Error(res.data.message);
      if (!requiredLoginFields.every((x) => Object.keys(res.data).indexOf(x) !== -1)) {
        throw new Error(`${INVALID_RESPONSE} ${LOGIN}`);
      }
      commit('setXsrfToken', res.headers['x-xsrf-token']);
      commit('setLoggedInUserData', {
        id: res.data.id,
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        avatar: res.data.avatar,
        expiresIn: res.data.expiresIn,
        loggedIn: true,
        roles: res.data.roles,
        permissions: res.data.permissions,
        clientIds: res.data.clientIds,
      });
      return !!res.data.twoFactorRequired || 'Second Factor Not Required';
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async logout({ commit, getters }, payload) {
    return axiosPost(`${getters.apiPrefix}/users/logout/${getters.loggedInUser('id')}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${LOGOUT}`);
      return true;
    }).finally(() => {
      commit('setLoggedInUserData', {
        id: null,
        email: '',
        expiresIn: null,
        loggedIn: false,
        roles: [],
        permissions: {
          get: [], delete: [], post: [], patch: [],
        },
      });
      clearInterval(getters.breachCronIntervalId);
      commit('updateBreachCronIntervalId', { id: null });
      clearInterval(getters.accessTokenIntervalId);
      commit('updateAccessTokenIntervalId', { id: null });
    });
    // Let calling functions handle errors for the logout action.
  },
  async setupTotp({ commit, getters }, { vm }) {
    return axiosPost(`${getters.apiPrefix}/users/setup-totp`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${SETUP_TOTP}`);
      commit('setQrData', res.data.data);
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async submitTotpCode({ getters }, { code }) {
    return axiosPost(`${getters.apiPrefix}/login/totp`, { code }, getters).then((res) => {
      if (!res.data) throw new Error('No response data.');
      return true;
    });
    // Let calling functions handle errors for the submitTotpCode action.
  },
  async sendPasswordResetLink({ getters }, { email, vm }) {
    return axios.post(`${getters.apiPrefix}/forgotpassword`, { email }).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${FORGOT_PASSWORD}`);
      if (res.data.error) throw new Error(res.data.message);
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updatePasswordFromLink({ getters }, { token, password, vm }) {
    const uri = `${getters.apiPrefix}/forgotpassword/${token}`;
    return axios.post(uri, { password }).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_PASSWORD_LINK}`);
      if (res.data.error) throw new Error(res.data.message);
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
};
