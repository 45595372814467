import {
  axiosGet,
  axiosPost,
  axiosPatch,
} from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const UPDATE_USER = 'updating user details.'; // njsscan-ignore: node_username
const UPDATE_PASSWORD = 'attempting to update user password.'; // njsscan-ignore: node_password
const REFRESH_USER = 'refreshing logged in user data.'; // njsscan-ignore: node_username
const TEST_EMAIL = 'attempting to send a test email.';
const UPLOAD_AVATAR = 'uploading user avatar.';
const GET_AVATAR = 'retrieving user avatar data.';

export default {
  async updateUserDetails({ dispatch, getters }, { updateFields, vm }) {
    return axiosPost(`${getters.apiPrefix}/users/my-data`, updateFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_USER}`);
      dispatch('refreshUserDetails', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async refreshUserDetails({ commit, getters }, payload) {
    return axiosGet(`${getters.apiPrefix}/users/my-data`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${REFRESH_USER}`);
      if (res.data.id && res.data.email) {
        commit('setLoggedInUserData', {
          id: res.data.id,
          email: res.data.email,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          avatar: res.data.avatar,
          roles: res.data.roles,
          permissions: res.data.permissions,
          clientIds: res.data.clientIds,
          loggedIn: true,
        });
      }
    }).catch((err) => console.log(err.message));
  },
  async sendTestEmail({ getters }, { vm }) {
    return axiosPost(`${getters.apiPrefix}/users/send-test-email`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${TEST_EMAIL}`);
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateLoggedInUserPassword({ getters }, {
    oldPassword, newPassword, userId, vm,
  }) {
    return axiosPatch(`${getters.apiPrefix}/users/update-password`,
      { oldPassword, newPassword, userId }, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_PASSWORD}`);
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async uploadAvatar({ dispatch, getters }, { formData, vm }) {
    // Use XHR directly instead of axios so that browser can set upload-specific headers for us.
    const request = new XMLHttpRequest();
    request.open('POST', `${getters.apiPrefix}/images/upload`);
    request.withCredentials = true;
    request.setRequestHeader('X-XSRF-TOKEN', getters.xsrfToken);
    const handleErrorEvent = (event) => {
      const response = JSON.parse(request.response || '{}');
      return vm.$root.errorSwal((response && response.message) || `${INVALID_RESPONSE} ${UPLOAD_AVATAR}`);
    };
    request.addEventListener('abort', handleErrorEvent);
    request.addEventListener('error', handleErrorEvent);
    request.addEventListener('loadend', (event) => {
      const response = JSON.parse(request.response || '{}');
      if (!response) {
        return vm.$root.errorSwal(`${INVALID_RESPONSE} ${UPLOAD_AVATAR}`);
      }
      if (request.status !== 200 || response.error) {
        return vm.$root.errorSwal(response.message);
      }
      dispatch('refreshUserDetails', {});
      return vm.$root.successSwal(response.message, () => vm.closeModal());
    });
    request.send(formData);
  },
  async getAvatar({ commit, getters }, { id }) {
    return id && axiosGet(`${getters.apiPrefix}/images/${id}`, { responseType: 'blob' }, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${GET_AVATAR}`);
      const reader = new FileReader();
      reader.readAsDataURL(res.data);
      reader.onload = () => { commit('setUserAvatar', { id, data: reader.result }); };
    }).catch((error) => {
      // set default avatar so we don't query this on every change
      commit('setUserAvatar', { id, data: getters.avatar(id) });
      return console.log(error.message);
    });
  },
};
