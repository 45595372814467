import {
  axiosGet,
  axiosPost,
  axiosPatch,
  axiosDelete,
} from '../../requests';

// Error message building blocks
const INVALID_RESPONSE = 'Invalid response when';
const CREATE_USER = 'creating a new user.'; // njsscan-ignore: node_username
const GET_USERS = 'retrieving user data.'; // njsscan-ignore: node_username
const DELETE_USER = 'deleting user account.'; // njsscan-ignore: node_username
const GET_ROLES = 'retrieving role data.';
const CREATE_ROLE = 'creating a new role.';
const UPDATE_ROLE = 'updating role details.';
const DELETE_ROLE = 'deleting role.';
const UPDATE_ROLE_CLIENTS = 'updating clients for role.';
const UPDATE_ROLES = 'updating user roles.'; // njsscan-ignore: node_username
const RESET_USER = 'resetting user account.'; // njsscan-ignore: node_username
const LOCK_USER = 'locking user account.'; // njsscan-ignore: node_username

export default {
  async createNewUser({ commit, dispatch, getters }, { userObject, vm }) {
    return axiosPost(`${getters.apiPrefix}/users`, userObject, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_USER}`);
      const { user, activationLink } = res.data.object;
      dispatch('getAllUsers', {}).then(() => commit('updateSelectedUser', user));
      return `${res.data.message} Activation Link: ${activationLink}`;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async getAllUsers({ commit, getters }, payload) {
    return axiosGet(`${getters.apiPrefix}/users`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${GET_USERS}`);
      commit('setAllUsers', res.data.filter((user) => user.roleNames.indexOf('system') === -1));
    }).catch((err) => console.log(err.message));
  },
  async refreshSelectedUser({ getters, commit }, { id }) {
    return axiosGet(`${getters.apiPrefix}/users/${id}`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${GET_USERS}`);
      commit('updateSelectedUser', res.data);
    }).catch((err) => console.log(err.message));
  },
  async getAllRoles({ commit, getters }, payload) {
    return axiosGet(`${getters.apiPrefix}/roles`, {}, commit).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${GET_ROLES}`);
      commit('setAvailableRoles', res.data.filter((role) => role.roleName !== 'system'));
    }).catch((err) => console.log(err.message));
  },
  async createNewRole({ dispatch, getters }, { roleObject, vm }) {
    return axiosPost(`${getters.apiPrefix}/roles`, roleObject, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${CREATE_ROLE}`);
      dispatch('getAllRoles', {});
      dispatch('getAllClients', {});
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateRoleDetails({ commit, dispatch, getters }, { updateFields, vm }) {
    return axiosPatch(`${getters.apiPrefix}/roles`, updateFields, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_ROLE}`);
      dispatch('getAllRoles', {}).then(() => commit('updateSelectedRole', null));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async deleteRole({ commit, dispatch, getters }, { roleId, vm }) {
    return axiosDelete(`${getters.apiPrefix}/roles/${roleId}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${DELETE_ROLE}`);
      dispatch('getAllRoles', {}).then(() => commit('updateSelectedRole', null));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateSelectedRoleClients({ commit, dispatch, getters },
    { proposedClientIds, roleId, vm }) {
    return axiosPost(`${getters.apiPrefix}/roleclients/updateclients`, { roleId, clientIdArr: proposedClientIds }, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_ROLE_CLIENTS}`);
      dispatch('getAllRoles', {}).then(() => commit('updateSelectedRole', null));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async updateSelectedUserRoles({ commit, dispatch, getters }, { proposedRoleIds, userId, vm }) {
    if (userId === getters.loggedInUser('id')) {
      throw new Error('You cannot update your own roles.');
    }
    return axiosPost(`${getters.apiPrefix}/roleusers/updateroles`, { userId, roleIdArr: proposedRoleIds }, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${UPDATE_ROLES}`);
      dispatch('getAllUsers', {}).then(() => commit('updateSelectedUser',
        getters.allUsers.find((user) => user.id === userId)));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async resetUser({ commit, dispatch, getters }, { userId, resetPassword, vm }) {
    if (userId === getters.loggedInUser('id')) {
      throw new Error('You cannot reset your own account.');
    }
    return axiosPost(`${getters.apiPrefix}/users/reset/${userId}`, { resetPassword }, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${RESET_USER}`);
      dispatch('getAllUsers', {}).then(() => commit('clearSelectedUser', {}));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async lockUser({ commit, dispatch, getters }, { userId, vm }) {
    if (userId === getters.loggedInUser('id')) {
      throw new Error('You cannot lock your own account.');
    }
    return axiosPost(`${getters.apiPrefix}/users/lock/${userId}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${LOCK_USER}`);
      dispatch('getAllUsers', {}).then(() => commit('clearSelectedUser', {}));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
  async deleteUser({ commit, dispatch, getters }, { userId, vm }) {
    if (userId === getters.loggedInUser('id')) {
      throw new Error('You cannot delete your own account.');
    }
    return axiosDelete(`${getters.apiPrefix}/users/${userId}`, {}, getters).then((res) => {
      if (!res.data) throw new Error(`${INVALID_RESPONSE} ${DELETE_USER}`);
      dispatch('getAllUsers', {}).then(() => commit('clearSelectedUser', {}));
      return res.data.message;
    }).catch((err) => vm.$root.errorSwal(err));
  },
};
