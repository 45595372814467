export default {
  setClients(state, clients) {
    state.clients = clients;
  },
  updateSelectedClient(state, { id }) {
    state.selectedClientId = id;
  },
  updateSelectedClientContact(state, clientContact) {
    state.selectedClientContact.id = clientContact.id;
    state.selectedClientContact.clientId = clientContact.clientId;
    state.selectedClientContact.firstName = clientContact.firstName;
    state.selectedClientContact.lastName = clientContact.lastName;
    state.selectedClientContact.email = clientContact.email;
    state.selectedClientContact.phone = clientContact.phone;
    state.selectedClientContact.preferred_medium = clientContact.preferred_medium;
    state.selectedClientContact.socTeam = clientContact.socTeam;
  },
  setDeployments(state, deployments) {
    state.deployments = deployments;
  },
  updateSelectedBox(state, box) {
    state.selectedBox.id = box.id;
    state.selectedBox.deploymentId = box.deploymentId;
    state.selectedBox.hostname = box.hostname;
    state.selectedBox.address = box.address;
    state.selectedBox.shouldPollAia = box.shouldPollAia;
    state.selectedBox.shouldSyncAiaComments = box.shouldSyncAiaComments;
    state.selectedBox.shouldPollBreaches = box.shouldPollBreaches;
    state.selectedBox.shouldSyncBreachComments = box.shouldSyncBreachComments;
    state.selectedBox.verified = box.verified;
    state.selectedBox.minPriority = box.minPriority;
    state.selectedBox.emOnly = box.emOnly;
    state.selectedBox.pollingInterval = box.pollingInterval;
    state.selectedBox.shouldPollRespondEmail = box.shouldPollRespondEmail;
    state.selectedBox.respondEmailPollingInterval = box.respondEmailPollingInterval;
    state.selectedBox.respondEmailMinScore = box.respondEmailMinScore;
  },
  setQueryLogs(state, queryLogs) {
    state.queryLogs = queryLogs;
  },
  updateQueryLogSearchString(state, { string }) {
    state.queryLogSearchString = string;
  },
  updateQueryLogStartDateFilterOption(state, { date }) {
    state.queryLogStartDate = date;
  },
  updateQueryLogEndDateFilterOption(state, { date }) {
    state.queryLogEndDate = date;
  },
};
