/* eslint-disable security/detect-object-injection */
const mergeAsmObject = (state, asmObject, type) => {
  const index = state.allAsmObjects.findIndex((obj) => obj.asmObjectUid === `${type}${asmObject.uid}`);
  if (index === -1) {
    state.allAsmObjects.push({ ...asmObject, type, asmObjectUid: `${type}${asmObject.uid}` });
  } else {
    state.allAsmObjects[index] = { ...asmObject, type, asmObjectUid: `${type}${asmObject.uid}` };
  }
};
const mergeAsmObjects = (state, asmObjects, type) => {
  asmObjects.forEach((asmObject) => mergeAsmObject(state, asmObject, type));
};
const mergeAsmRisks = (state, asmRisks) => {
  asmRisks.forEach((asmRisk) => {
    const index = state.allAsmRisks.findIndex((obj) => obj.uid === asmRisk.uid);
    if (index === -1) {
      state.allAsmRisks.push(asmRisk);
    } else {
      state.allAsmRisks[index] = asmRisk;
    }
  });
};
export default {
  clearAsmObjects(state, payload) {
    state.allAsmObjects = [];
    state.allAsmRisks = [];
  },
  mergeAsmApplications(state, asmApplications) {
    asmApplications.forEach((application) => {
      mergeAsmObject(state, application, 'application');
      mergeAsmRisks(state, application.asmRisks.map((asmRisk) => {
        return {
          ...asmRisk,
          applicationId: application.application_id,
          applicationTitle: application.title,
          clientId: application.clientId,
          clientName: application.client.name,
          clientAsmAddress: application.client.asmAddress,
        };
      }));
    });
  },
  mergeAsmDomains(state, domains) { mergeAsmObjects(state, domains, 'domain'); },
  mergeAsmFqdns(state, fqdns) { mergeAsmObjects(state, fqdns, 'fqdn'); },
  mergeAsmIps(state, ips) { mergeAsmObjects(state, ips, 'ip'); },
  mergeAsmNetblocks(state, netblocks) { mergeAsmObjects(state, netblocks, 'netblock'); },
  updateSelectedAsmObjectState(state, id) { state.selectedAsmObjectStateId = id; },
  updateSelectedAsmRiskType(state, { id }) { state.selectedAsmRiskTypeId = id; },
  setMinAsmRiskScore(state, minAsmRiskScore) { state.minAsmRiskScore = minAsmRiskScore; },
  setMaxAsmRiskScore(state, maxAsmRiskScore) { state.maxAsmRiskScore = maxAsmRiskScore; },
  sortAsmObjects(state) {
    state.allAsmObjects = state.allAsmObjects.sort((a, b) => {
      return (['f', 'e', 'd', 'c', 'b', 'a', 'u'].indexOf(a.securityrating)
            - ['f', 'e', 'd', 'c', 'b', 'a', 'u'].indexOf(b.securityrating))
            || b.updated_at - a.updated_at;
    });
  },
  sortAsmRisks(state) {
    state.allAsmRisks = state.allAsmRisks.sort((a, b) => {
      return b.score - a.score || b.started_at - a.started_at;
    });
  },
};
